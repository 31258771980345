import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateRandomString, useMediaQuery } from './shared-functions.js';
import { db, storage } from "./use-firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useRequireAuth } from "./use-require-auth.js";
import { collection, addDoc, getDocs, query, where, updateDoc, doc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Image, Modal, Form, InputGroup } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import {  } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bootIntercom } from './shared-intercom.js';
import CommonHelmet from "./components/common/Helmet.js";

export default function Settings() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)');
  const [name, setName] = useState(null);
  const [webUrl, setWebUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [twitterHandle, setTwitterHandle] = useState(null);
  const [instagramHandle, setInstagramHandle] = useState(null);
  const [facebookHandle, setFacebookHandle] = useState(null);
  const [linkedinHandle, setLinkedinHandle] = useState(null);
  const [validated, setValidated] = useState(false);
  const [photoError, setPhotoError] = useState(false);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      setName(auth.workspace.name ? auth.workspace.name : null);
      setWebUrl(auth.workspace.webUrl ? auth.workspace.webUrl : null);
      setTwitterHandle(auth.workspace.twitterHandle ? auth.workspace.twitterHandle : null);
      setInstagramHandle(auth.workspace.instagramHandle ? auth.workspace.instagramHandle : null);
      setFacebookHandle(auth.workspace.facebookHandle ? auth.workspace.facebookHandle : null);
      setLinkedinHandle(auth.workspace.linkedinHandle ? auth.workspace.linkedinHandle : null);
      setLogoUrl(auth.workspace.logoUrl ? auth.workspace.logoUrl : null);
      // boot intercom
      bootIntercom(auth.user.uid, auth.user.name, auth.user.email, auth.user.createdTs);
    }
    window.scrollTo(0,0);
  }, [auth]);

  const handleValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        saveWorkspaceProfile();
      }
      setValidated(true);
    }
  };

  const saveWorkspaceProfile = () => {
    getDocs(query(collection(db, "workspaces"), where("id", "==", auth.workspace.id))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        let _workspace = _doc.data();
        _workspace.name = name;
        _workspace.webUrl = webUrl;
        _workspace.twitterHandle = twitterHandle;
        _workspace.instagramHandle = instagramHandle;
        _workspace.facebookHandle = facebookHandle;
        _workspace.linkedinHandle = linkedinHandle;
        updateDoc(doc(db, "workspaces", _doc.id), {
          name: name,
          webUrl: webUrl,
          twitterHandle: twitterHandle,
          instagramHandle: instagramHandle,
          facebookHandle: facebookHandle,
          linkedinHandle: linkedinHandle
        }).then(() => {
          auth.changeWorkspace(_workspace);
          doToastSuccess('Changes saved!');
        });
      });
    });
  };

  const handleLogoChange = e => {
    if (e.target.files[0]) {
      const photo = e.target.files[0];
      // console.log(cover);
      // jpg or png images, less than 2MB
      if ((photo.type === "image/png" || photo.type === "image/jpeg" || photo.type === "image/jpg") && (photo.size/1000000 < 1)) {
        setPhotoError(false);
        handlePhotoUpload(photo);
      } else {
        setPhotoError(true);
      }
    }
  };

  const handlePhotoUpload = (cover) => {
    let id = generateRandomString(24);
    const storageRef = ref(storage, 'logos/' + id);
    const uploadTask = uploadBytesResumable(storageRef, cover);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
        }
      },
      (error) => {
        console.log("logo upload error");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          setLogoUrl(downloadUrl);
        });
      }
    );
  };

  const saveWorkspaceLogo = () => {
    getDocs(query(collection(db, "workspaces"), where("id", "==", auth.workspace.id))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        let _workspace = _doc.data();
        _workspace.logoUrl = logoUrl;
        updateDoc(doc(db, "workspaces", _doc.id), { logoUrl: logoUrl }).then(() => {
          auth.changeWorkspace(_workspace);
          doToastSuccess('Logo saved!');
        });
      });
    });
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <CommonHelmet />
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <h3>Workspace Settings</h3>
          </Row>
          <hr style={{ marginTop: 40 }} />
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <Col xs={12} sm={10} md={4} lg={5} xl={6}>
              <h4>Profile</h4>
            </Col>
            <Col xs={12} sm={10} md={8} lg={7} xl={6}>
              <Form noValidate validated={validated} onSubmit={handleValidation}>
                <Form.Label style={{ marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Name</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control required as="input" type="text" minLength="3" maxLength="96" value={name} onChange={(e) => setName(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid name 3 to 96 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Website URL</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. https://www.google.com" value={webUrl} onChange={(e) => setWebUrl(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid URL
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>LinkedIn handle</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control as="input" type="text" minLength="3" maxLength="96" value={linkedinHandle} onChange={(e) => setLinkedinHandle(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid handle
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Twitter handle</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control as="input" type="text" minLength="3" maxLength="96" value={twitterHandle} onChange={(e) => setTwitterHandle(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid handle
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Instagram handle</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control as="input" type="text" minLength="3" maxLength="96" value={instagramHandle} onChange={(e) => setInstagramHandle(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid handle
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6 style={{ marginBottom: 0 }}>Facebook page handle</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control as="input" type="text" minLength="3" maxLength="96" value={facebookHandle} onChange={(e) => setFacebookHandle(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid handle
                  </Form.Control.Feedback>
                </Form.Group>
                <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                  <Button variant="secondary" type="submit">Save changes</Button>
                </Row>
              </Form>
            </Col>
          </Row>
          <hr style={{ marginTop: 40 }}/>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <Col xs={12} sm={10} md={4} lg={5} xl={6}>
              <h4>Logo</h4>
            </Col>
            <Col xs={12} sm={10} md={8} lg={7} xl={6}>
              { logoUrl &&
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginBottom: 10 }}>
                  <Image src={ logoUrl } style={{ height: 40, objectFit: 'cover' }} />
                </Row>
              }
              <Form>
                <InputGroup className="mb-3" style={{ marginTop: 10 }}>
                  <Form.Control type="file" onChange={handleLogoChange} style={{ border: 0, padding: 0, backgroundColor: '#FFFFFF' }}/>
                </InputGroup>
                { photoError &&
                  <p className="small text-danger">Upload PNGs/JPGs less than 2MB in size</p>
                }
                <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                  <Button variant="secondary" onClick={() => saveWorkspaceLogo()}>Save changes</Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  );

}
