import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateRandomString, useMediaQuery } from './shared-functions.js';
import { db, storage } from "./use-firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useRequireAuth } from "./use-require-auth.js";
import { collection, getDocs, where, query, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Table, Spinner, Modal, Form, Image, InputGroup, Badge, Card } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { GlobeSimple, InstagramLogo, FacebookLogo, Copy, PencilSimple, UploadSimple, DownloadSimple, Quotes, Envelope, Eye, EyeSlash, CodeSimple, ArrowLeft, TwitterLogo, LinkedinLogo, Checks, Wall, Columns, Link } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PostCard from "./components/cards/Post.js";
import ReactPlayer from 'react-player';
import Moment from 'react-moment';
import { XMasonry, XBlock } from "react-xmasonry";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { WithContext as ReactTags } from 'react-tag-input';
import { TwitterEmbed, InstagramEmbed } from 'react-social-media-embed';
import StarRatings from 'react-star-ratings';
import * as UpChunk from '@mux/upchunk';
import MuxPlayer from '@mux/mux-player-react';
import { bootIntercom } from './shared-intercom.js';
import CommonHelmet from "./components/common/Helmet.js";
import { mixpanelTrackSelfUpload } from './shared-mixpanel.js';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Resizer from "react-image-file-resizer";

export default function Showcase() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const props = useParams();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [showcase, setShowcase] = useState(null);
  const [posts, setPosts] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [quoteModal, setQuoteModal] = useState(false);
  const [embedModal, setEmbedModal] = useState(false);
  const [postType, setPostType] = useState(null);
  const [muxId, setMuxId] = useState(null);
  const [muxPlaybackId, setMuxPlaybackId] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [importUrl, setImportUrl] = useState(null);
  const [importSource, setImportSource] = useState('twitter');
  const [showPreviewVideo, setShowPreviewVideo] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [photoError, setPhotoError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [comment, setComment] = useState(null);
  const [quote, setQuote] = useState(null);
  const [name, setName] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [linkedinUsername, setLinkedinUsername] = useState(null);
  const [twitterUsername, setTwitterUsername] = useState(null);
  const [instagramUsername, setInstagramUsername] = useState(null);
  const [tags, setTags] = useState([]);
  const [filterTag, setFilterTag] = useState('All');
  const [photoProgress, setPhotoProgress] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);
  const [postTags, setPostTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const KeyCodes = { comma: 188, enter: 13 }
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const videoInput = useRef(null);
  const photoInput = useRef(null);
  const [showPublicOnly, setShowPublicOnly] = useState(false);
  const [embedLayout, setEmbedLayout] = useState('wall');
  const [embedString, setEmbedString] = useState(generateRandomString(6));

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      // get showcase
      if (props === undefined || props.id === undefined) {
        navigate('/notfound');
      } else {
        getDocs(query(collection(db, "showcases"), where("id", "==", props.id), where("workspaceId", "==", auth.workspace.id))).then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            querySnapshot.forEach((doc) => {
              setShowcase(doc.data());
              getDocs(query(collection(db, "posts"), where("showcaseId", "==", doc.data().id), where("workspaceId", "==", auth.workspace.id))).then((querySnapshot2) => {
                let _posts = []
                let _tags = [];
                querySnapshot2.forEach((doc2) => {
                  _posts.push(doc2.data());
                  if (doc2.data().tags) {
                    doc2.data().tags.forEach((t, i) => {
                      if (!_tags.includes(t.text)) {
                        _tags.push(t.text);
                      }
                    });
                  }
                });
                setTags(_tags);
                setPosts(_posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1));
              });
            });
          } else {
            navigate('/notfound');
          }
        });
      }

      // boot intercom
      bootIntercom(auth.user.uid, auth.user.name, auth.user.email, auth.user.createdTs);

    }
    window.scrollTo(0,0);
  }, [auth]);

  const handleNewValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        if (postType === 'photo') {
          savePhotoAsPost();
        } else {
          saveVideoAsPost();
        }
      }
      setValidated(true);
    }
  };

  const handleImportValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        saveUrlAsPost();
      }
      setValidated(true);
    }
  };

  const saveVideoAsPost = () => {
    let newVideoPost = {
      id: generateRandomString(16),
      showcaseId: showcase.id,
      workspaceId: showcase.workspaceId,
      name: name,
      rating: 0,
      comment: comment,
      jobTitle: jobTitle,
      muxId: muxId,
      muxPlaybackId: muxPlaybackId,
      linkedinUsername: linkedinUsername,
      twitterUsername: twitterUsername,
      instagramUsername: instagramUsername,
      tags: postTags,
      type: 'video',
      approved: true,
      publicVisibility: true,
      createdTs: Date.now(),
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "posts"), newVideoPost).then(() => {
      doToastSuccess("Video saved!");
      setUploadModal(false);
      // mixpanel track new self upload
      mixpanelTrackSelfUpload(auth.user.uid, auth.workspace.id, showcase.id, 'video');
      window.location.reload(true);
    })
  };

  const savePhotoAsPost = () => {
    let newPhotoPost = {
      id: generateRandomString(16),
      showcaseId: showcase.id,
      workspaceId: showcase.workspaceId,
      name: name,
      rating: 0,
      comment: comment,
      jobTitle: jobTitle,
      photoUrl: photoUrl,
      linkedinUsername: linkedinUsername,
      twitterUsername: twitterUsername,
      instagramUsername: instagramUsername,
      tags: postTags,
      type: 'photo',
      approved: true,
      publicVisibility: true,
      createdTs: Date.now(),
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "posts"), newPhotoPost).then(() => {
      doToastSuccess("Photo saved!");
      setPhotoUrl(null);
      setUploadModal(false);
      // mixpanel track new self upload
      mixpanelTrackSelfUpload(auth.user.uid, auth.workspace.id, showcase.id, 'photo');
      window.location.reload(true);
    })
  };

  const saveUrlAsPost = () => {
    let newUrlPost = {
      id: generateRandomString(16),
      showcaseId: showcase.id,
      workspaceId: showcase.workspaceId,
      comment: comment,
      name: name,
      jobTitle: jobTitle,
      linkedinUsername: linkedinUsername,
      twitterUsername: twitterUsername,
      instagramUsername: instagramUsername,
      source: importSource,
      url: importUrl,
      tags: postTags,
      type: 'url',
      approved: true,
      publicVisibility: true,
      createdTs: Date.now(),
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "posts"), newUrlPost).then(() => {
      doToastSuccess("Import saved!");
      setImportUrl(null);
      setImportModal(false);
      window.location.reload(true);
    })
  };

  const getMuxPlaybackId = (_id) => {
    getDocs(query(collection(db, "muxvideos"), where("id", "==", _id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().status === 'ready' && doc.data().asset && doc.data().asset.playback_ids) {
          setMuxPlaybackId(doc.data().asset.playback_ids[0].id);
        }
      });
    });
  };

  const handleVideoChange = e => {
    if (e.target.files[0]) {
      const video = e.target.files[0];
      if ((video.type === "video/mpg" || video.type === "video/mp4" || video.type === "video/mov" || video.type === "video/quicktime") && (video.size/1000000 < 200)) {
        setVideoError(false);
        // handleVideoUpload(video);
        setPostType('video');
        setPhotoUrl(null);
        handleVideoUploadToMux(video);
      } else {
        setVideoError(true);
      }
    }
  };

  const resizeFile = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 900, 900, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64' );
  });

  const dataURIToBlob = (dataURI, filename) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    let blob = new Blob([ia], { type: mimeString });

    return new File([blob], filename, { lastModified: Date.now(), type: 'image/jpeg' });
  };

  const handlePhotoChange = async e => {
    if (e.target.files[0]) {
      const photo = e.target.files[0];
      // console.log("photo", photo);
      // jpg or png images, less than 2MB
      // && (photo.size/1000000 < 6)
      if ((photo.type === "image/png" || photo.type === "image/jpeg" || photo.type === "image/jpg")) {
        setPhotoError(false);
        setPostType('photo');
        const resizedPhoto = await resizeFile(photo);
        const image = dataURIToBlob(resizedPhoto, generateRandomString(16) + ".jpeg");
        // console.log("image", image);
        handlePhotoUpload(image);
      } else {
        setPhotoError(true);
      }
    }
  };

  // const handlePhotoChange = e => {
  //   if (e.target.files[0]) {
  //     const photo = e.target.files[0];
  //     // jpg or png images, less than 2MB
  //     if ((photo.type === "image/png" || photo.type === "image/jpeg" || photo.type === "image/jpg") && (photo.size/1000000 < 2)) {
  //       setPhotoError(false);
  //       setPostType('photo');
  //       handlePhotoUpload(photo);
  //     } else {
  //       setPhotoError(true);
  //     }
  //   }
  // };

  const handlePhotoUpload = (photo) => {
    let id = generateRandomString(24);
    const metadata = { contentType: photo.type, customMetadata: { ruleCode: process.env.REACT_APP_FIREBASE_STORAGE_CODE }};
    const storageRef = ref(storage, 'photos/' + id);
    const uploadTask = uploadBytesResumable(storageRef, photo, metadata);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        setPhotoProgress(progress);
        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
        }
      },
      (error) => {
        console.log("photo upload error");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          setPhotoUrl(downloadUrl);
        });
      }
    );
  };

  const handleVideoUploadToMux = (video) => {
    setShowPreviewVideo(false);
    fetch('https://us-central1-nikos-app.cloudfunctions.net/getMuxLink', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        key: 'value',
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response", responseJson);
      let uploadUrl = responseJson.url;
      setMuxId(responseJson.id)
      // upload
      const upload = UpChunk.createUpload({
        endpoint: uploadUrl, // Authenticated url
        file: video, // File object with your video file’s properties
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });
      // Subscribe to events
      upload.on('error', error => {
        // console.log("error", error.detail);
        doToastError("Something went wrong. Try again.")
      });
      upload.on('progress', progress => {
        setVideoProgress(progress.detail);
        // console.log("progress", progress.detail);
      });
      upload.on('success', () => {
        setShowPreviewVideo(true);
        const timer = setTimeout(() => {
          getMuxPlaybackId(responseJson.id);
        }, 10000);
        return () => clearTimeout(timer);
      });
    })
  }

  const handleDelete = i => {
    let _tags = postTags.filter((tag, index) => index !== i)
    setPostTags(_tags);
  };

  const handleAddition = tag => {
    let _tag = { id: tag.id, text: tag.text.replace(/#/g,'') }
    let newTags = [...postTags, _tag];
    setPostTags(newTags)
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = postTags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setPostTags(newTags);
  };

  const handleTagClick = index => {
    // console.log('The tag at index ' + index + ' was clicked');
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (showcase === null) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <CommonHelmet />
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <p className="small muted" style={{ textAlign: 'left' }}><a href={"/showcases"}><ArrowLeft /> Back to my showcases</a></p>
          <h3 style={{ marginTop: 10, textAlign: 'left' }}>{ showcase.name }</h3>
          <Row style={{ flexDirection: 'row', justifyContent: 'left', alignItems: 'center', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
            <Col xs={10} sm={9} md={8} lg={7} xl={5} style={{ textAlign: 'left', padding: 0 }}>
              <Badge bg={ showcase.publicVisibility ? 'success' : 'dark' } style={{ textAlign: 'center', color: '#EEEEEE' }}>{ showcase.publicVisibility ? 'Public' : 'Hidden' }</Badge>
              <p className="small muted" style={{ marginTop: 10 }}>{ showcase.description }</p>
            </Col>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 10, marginLeft: 0, marginRight: 0, width: '100%' }}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0, marginBottom: 10 }}>
              { (auth.workspace.webUrl || auth.workspace.linkedinHandle || auth.workspace.twitterHandle || auth.workspace.facebookHandle || auth.workspace.instagramHandle) &&
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                  { auth.workspace.webUrl &&
                    <a href={auth.workspace.webUrl} target="_blank" rel="noopener noreferrer"><GlobeSimple size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { auth.workspace.linkedinHandle &&
                    <a href={'https://www.linkedin.com/company/' + auth.workspace.linkedinHandle} target="_blank" rel="noopener noreferrer"><LinkedinLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { auth.workspace.twitterHandle &&
                    <a href={'https://www.twitter.com/' + auth.workspace.twitterHandle} target="_blank" rel="noopener noreferrer"><TwitterLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { auth.workspace.facebookHandle &&
                    <a href={'https://www.facebook.com/' + auth.workspace.facebookHandle} target="_blank" rel="noopener noreferrer"><FacebookLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { auth.workspace.instagramHandle &&
                    <a href={'https://www.instagram.com/' + auth.workspace.instagramHandle} target="_blank" rel="noopener noreferrer"><InstagramLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                </Row>
              }
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <p className="small muted" style={{ marginRight: 10 }}>Share</p>
                <Link size={24} color={'#111111'} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://content.nikos.ai/showcases/" + showcase.id); doToastSuccess('Link copied to clipboard') }} />
                <EmailShareButton subject={"Check out " + showcase.name} body={"I think you will find this interesting. They are " + showcase.name + " from " + auth.workspace.name} source="Nikos" url={"https://content.nikos.ai/showcases/" + showcase.id} style={{ marginRight: 10 }}>
                    <EmailIcon size={30} round={true} />
                </EmailShareButton>
                <LinkedinShareButton title={"Check out " + showcase.name + " from " + auth.workspace.name} summary={showcase.description} source="Nikos" url={"https://content.nikos.ai/showcases/" + showcase.id} style={{ marginRight: 10 }}>
                    <LinkedinIcon size={30} round={true} />
                </LinkedinShareButton>
                <FacebookShareButton quote={"Check out " + showcase.name + " from " + auth.workspace.name} summary="" source="Nikos" url={"https://content.nikos.ai/showcases/" + showcase.id} style={{ marginRight: 10 }}>
                    <FacebookIcon size={30} round={true} />
                </FacebookShareButton>
                <WhatsappShareButton title={"Check out " + showcase.name + " from " + auth.workspace.name} url={"https://content.nikos.ai/showcases/" + showcase.id} style={{ marginRight: 10 }}>
                    <WhatsappIcon size={30} round={true} />
                </WhatsappShareButton>
                <TwitterShareButton title={"Check out " + showcase.name + " from " + auth.workspace.name} url={"https://content.nikos.ai/showcases/" + showcase.id} style={{ marginRight: 10 }}>
                    <TwitterIcon size={30} round={true} />
                </TwitterShareButton>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <p className="small" style={{ backgroundColor: '#EEEEEE', padding: '8px 20px', borderRadius: 5, marginRight: 20, marginBottom: 5, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/request/" + showcase.gatherCode); doToastSuccess('Request URL copied to clipboard') }}><Copy size={16} style={{ marginRight: 5 }} /> Copy request URL</p>
            <a href={"https://content.nikos.ai/showcases/" + showcase.id} target="_blank" rel="noopener noreferrer"><p className="small" style={{ backgroundColor: '#EEEEEE', padding: '8px 20px', borderRadius: 5, marginRight: 20, marginBottom: 5, cursor: 'pointer' }}><Eye size={16} style={{ marginRight: 5 }} /> Open public page</p></a>
            <p className="small" style={{ backgroundColor: '#EEEEEE', padding: '8px 20px', borderRadius: 5, marginRight: 20, marginBottom: 5, cursor: 'pointer' }} onClick={() => setUploadModal(true)}><UploadSimple size={16} style={{ marginRight: 5 }} /> Upload { showcase.showcaseType }</p>
            <p className="small" style={{ backgroundColor: '#EEEEEE', padding: '8px 20px', borderRadius: 5, marginRight: 20, marginBottom: 5, cursor: 'pointer' }} onClick={() => setImportModal(true)}><DownloadSimple size={16} style={{ marginRight: 5 }} /> Import social</p>
            <p className="small" style={{ backgroundColor: '#EEEEEE', padding: '8px 20px', borderRadius: 5, marginRight: 20, marginBottom: 5, cursor: 'pointer' }} onClick={() => navigate('/showcases/edit/' + showcase.id)}><PencilSimple size={16} style={{ marginRight: 5 }} /> Edit showcase</p>
            <p className="small" style={{ backgroundColor: '#EEEEEE', padding: '8px 20px', borderRadius: 5, marginRight: 20, marginBottom: 5, cursor: 'pointer' }} onClick={() => { setEmbedModal(true) }}><CodeSimple size={16} style={{ marginRight: 5 }} /> Copy embed code</p>
          </Row>
          <hr />
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <Col xs={10} sm={10} md={10} lg={10} xl={10} style={{ paddingLeft: 0 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <p className="small" style={{ marginRight: 10, marginBottom: 10, borderRadius: 20, backgroundColor: filterTag === "All" ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag('All')}>All</p>
                {
                  tags.map((t, id) => {
                    return (
                      <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: t == filterTag ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag(t)}>{ t }</p>
                    )
                  })
                }
              </Row>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ textAlign: 'right' }}>
              { showPublicOnly &&
                <Eye size="22" style={{ cursor: 'pointer' }} onClick={() => setShowPublicOnly(false)} />
              }
              { !showPublicOnly &&
                <EyeSlash size="22" style={{ cursor: 'pointer' }} onClick={() => setShowPublicOnly(true)} />
              }
            </Col>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              { posts.length === 0 &&
                <p className="small" style={{ marginTop: 10, textAlign: 'center' }}>No posts yet</p>
              }
              { posts.length > 0 &&
                <ResponsiveMasonry columnsCountBreakPoints={{ 650: 1, 850: 2, 1000: 3, 1500: 4 }} style={{ width: '100%', padding: 0 }}>
                  <Masonry gutter="0px">
                    {
                      posts.map((p) => {
                        if (filterTag === 'All' || (p.tags && p.tags.some(t => t.text === filterTag))) {
                          if (!showPublicOnly || p.publicVisibility) {
                            return (
                              <PostCard post={p} />
                            )
                          }
                        }
                      })
                    }
                  </Masonry>
                </ResponsiveMasonry>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        show={uploadModal}
        centered
        size="md"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setUploadModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h4>Upload</h4>
            <Form noValidate validated={validated} onSubmit={handleNewValidation} style={{ marginTop: 0 }}>
            <InputGroup className="mb-3">
              <Form.Control type="file" onChange={handleVideoChange} ref={videoInput} style={{ display: 'none' }}/>
            </InputGroup>
            <InputGroup className="mb-3">
              <Form.Control type="file" onChange={handlePhotoChange} ref={photoInput} style={{ display: 'none' }}/>
            </InputGroup>
            <Card style={{ backgroundColor: '#eeeeee', height: 80, cursor: 'pointer' }} onClick={() => videoInput.current.click()}>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <h5 className="muted">Upload a video</h5>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                <p className="small" style={{ cursor: 'pointer' }} onClick={() => photoInput.current.click()}>or upload a photo</p>
              </Row>
            </Card>
            { videoError &&
              <p className="text-danger small" style={{ marginTop: 20 }}>Upload MP4/MPG/MOV files less than 200MB in size</p>
            }
            { videoProgress > 0 && videoProgress < 100 &&
              <p className="small muted">Uploading ... { parseInt(videoProgress) }%</p>
            }
            { showPreviewVideo && !muxPlaybackId && !photoUrl &&
              <Card style={{ height: 200, marginTop: 20, backgroundColor: '#EEEEEE' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                  <Checks size={22} color="#02ca4f" weight="bold" />
                  <p className="bold small" style={{ color: '#02ca4f', marginTop: 5 }}>Uploaded</p>
                </Row>
              </Card>
            }
            { showPreviewVideo && muxPlaybackId && !photoUrl &&
              <div style={{ marginTop: 20 }}>
                <MuxPlayer
                  streamType="on-demand"
                  playbackId={muxPlaybackId}
                  primaryColor="#CCCCCC"
                  width="100%"
                  metadata={{
                    video_id: muxId,
                    video_title: name
                  }}
                />
              </div>
            }
            { photoError && !photoUrl &&
              <p className="small text-danger" style={{ marginTop: 20 }}>Upload PNGs/JPGs less than 2MB in size</p>
            }
            { photoProgress > 0 && photoProgress < 100 &&
              <p className="small muted">Uploading ... { parseInt(photoProgress) }%</p>
            }
            { photoUrl &&
              <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginLeft: 0, marginRight: 0, width: '100%' }}>
                <Image src={ photoUrl } style={{ maxHeight: 200, objectFit: 'cover' }} />
              </Row>
            }
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>Add a Comment</h6><p className="small muted">Optional</p>
            </Form.Label>
            <Form.Group controlId="name">
              <Form.Control as="textarea" type="text" maxLength="500" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} />
            </Form.Group>
            { showcase.extraName &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>Name</h6>
                </Form.Label>
                <Form.Group controlId="name">
                  <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Ava Ko" value={name} onChange={(e) => setName(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid name 3 to 96 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            { showcase.extraJobTitle &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>Job title</h6>
                </Form.Label>
                <Form.Group controlId="jobtitle">
                  <Form.Control as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Director of Marketing" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid job title 3 to 96 characters in length
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            { showcase.extraLinkedin &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>LinkedIn Handle</h6>
                </Form.Label>
                <Form.Group controlId="linkedin">
                  <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={linkedinUsername} onChange={(e) => setLinkedinUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid LinkedIn handle up to 96 characters long
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            { showcase.extraTwitter &&
              <div>
                <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                  <h6>Twitter Handle</h6>
                </Form.Label>
                <Form.Group controlId="linkedin">
                  <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={twitterUsername} onChange={(e) => setTwitterUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Enter a valid LinkedIn handle up to 96 characters long
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            }
            <h6 style={{ marginTop: 20, marginBottom: 5 }}>Tags</h6>
            <div style={{ backgroundColor: 'transparent' }}>
              <ReactTags
                tags={postTags}
                suggestions={suggestions}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                inline={true}
                placeholder={"+ Add tags"}
                inputFieldPosition="inline"
                autofocus={false}
                autocomplete
              />
            </div>
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="tertiary" onClick={() => setUploadModal(false)}>Cancel</Button>
              <Button variant="primary" type="submit" disabled={(!showPreviewVideo && !comment) ? true : false }>Save</Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={embedModal}
        centered
        size="md"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setEmbedModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h4>Embed code</h4>
          <p className="small" style={{ marginTop: 10 }}>Choose a layout and copy the embed code for it. Paste the code in your website's HTML as is. If you use a web design tool such as Webflow, paste the code inside it's Embed block.</p>
          <p className="small muted" style={{ marginTop: 10 }}>Choose layout</p>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
            <Col style={{ textAlign: 'center' }}>
              <p className="small" style={{ padding: '20px', borderRadius: 5, height: 60, backgroundColor: embedLayout === 'wall' ? '#444444' : '#EEEEEE', color: embedLayout === 'wall' ? '#FFFFFF' : '#111111', cursor: 'pointer' }} onClick={() => setEmbedLayout('wall')}><Wall size={18} color={embedLayout === 'wall' ? '#EEEEEE' : '#333333'} weight="bold" style={{ marginRight: 2 }} /> Wall</p>
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <p className="small" style={{ padding: '20px', borderRadius: 5, height: 60, backgroundColor: embedLayout === 'carousel' ? '#444444' : '#EEEEEE', color: embedLayout === 'carousel' ? '#FFFFFF' : '#111111', cursor: 'pointer' }} onClick={() => setEmbedLayout('carousel')}><Columns size={18} color={embedLayout === 'carousel' ? '#EEEEEE' : '#333333'} weight="bold" style={{ marginRight: 2 }} /> Carousel</p>
            </Col>
          </Row>
          <p className="small muted" style={{ marginTop: 20 }}>Copy embed code</p>
          <Form.Control as="textarea" type="text" rows={8} style={{ marginTop: 5 }} value={'<script type="text/javascript" src="https://firebasestorage.googleapis.com/v0/b/nikos-app.appspot.com/o/js%2FiframeResizer.js?alt=media&token=ee65c4bb-1e4f-4c71-821f-c2d69c524de2"></script><iframe id="nikos-ai-showcase-' + embedString + '" src="https://app.nikos.ai/p/' + showcase.id + '/embed?layout=' + embedLayout + '" title="' + showcase.name + ' - Nikos" frameborder="0" scrolling="no" allowtransparency="true" width="100%"></iframe><script type="text/javascript">iFrameResize({log: false, enablePublicMethods: true, heightCalculationMethod: "lowestElement", checkOrigin: false}, "#nikos-ai-showcase-' + embedString + '");</script>'} />
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
            <p className="small" style={{ marginTop: 10, padding: '5px 10px', backgroundColor: '#EEEEEE', cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText('<script type="text/javascript" src="https://firebasestorage.googleapis.com/v0/b/nikos-app.appspot.com/o/js%2FiframeResizer.js?alt=media&token=ee65c4bb-1e4f-4c71-821f-c2d69c524de2"></script><iframe id="nikos-ai-showcase-' + embedString + '" src="https://app.nikos.ai/p/' + showcase.id + '/embed?layout=' + embedLayout + '" title="' + showcase.name + ' - Nikos" frameborder="0" scrolling="no" allowtransparency="true" width="100%"></iframe><script type="text/javascript">iFrameResize({log: false, enablePublicMethods: true, heightCalculationMethod: "lowestElement", checkOrigin: false}, "#nikos-ai-showcase-' + embedString + '");</script>'); doToastSuccess('Copied embed code to clipboard') }}><Copy size={16} style={{ marginRight: 5 }} /> Copy code to clipboard</p>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 30 }}>
            <Button variant="tertiary" onClick={() => setEmbedModal(false)}>Close</Button>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={importModal}
        centered
        size="lg"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setImportModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h4>Import social posts</h4>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
            <TwitterLogo size={32} color={ importSource === 'twitter' ? 'green' : '#CCCCCC' } style={{ marginRight: 20, marginBottom: 5, cursor: 'pointer' }} onClick={() => setImportSource('twitter')}/>
            <InstagramLogo size={32} color={ importSource === 'instagram' ? 'green' : '#CCCCCC' } style={{ marginBottom: 5, cursor: 'pointer' }} onClick={() => setImportSource('instagram')}/>
          </Row>
          <Form noValidate validated={validated} onSubmit={handleImportValidation} style={{ marginTop: 0 }}>
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>{ importSource } URL</h6>
              { importSource === 'twitter' &&
                <p className="small muted">Open a tweet on twitter.com in a browser window. Copy the URL of the tweet from the address bar and paste it below. e.g. https://twitter.com/BTS_twt/status/1294895899005480960</p>
              }
              { importSource === 'instagram' &&
                <p className="small muted">Open a post on instagram.com in a browser window. Copy the URL of the post from the address bar and paste it below. e.g. https://www.instagram.com/p/CmUv48DLvxd</p>
              }
            </Form.Label>
            <Form.Group controlId="url">
              <Form.Control required as="input" type="text" minLength="3" maxLength="96" value={importUrl} onChange={(e) => setImportUrl(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter a valid URL
              </Form.Control.Feedback>
            </Form.Group>
            { importUrl && importSource === 'twitter' &&
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <TwitterEmbed url={importUrl} maxHeight="400px" />
              </div>
            }
            { importUrl && importSource === 'instagram' &&
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <InstagramEmbed url={importUrl} maxHeight="400px" captioned />
              </div>
            }
            <h6 style={{ marginTop: 20, marginBottom: 5 }}>Tags</h6>
            <div style={{ backgroundColor: 'transparent' }}>
              <ReactTags
                tags={postTags}
                suggestions={suggestions}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                inline={true}
                placeholder={"+ Add tags"}
                inputFieldPosition="inline"
                autofocus={false}
                autocomplete
              />
            </div>
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="tertiary" onClick={() => setImportModal(false)}>Cancel</Button>
              <Button variant="primary" type="submit" style={{ marginTop: 20 }} disabled={!importUrl ? true : false }>Save</Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}

// <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//   <h6>Add a Rating</h6>
// </Form.Label>
// <div>
//   <StarRatings
//     rating={rating}
//     starRatedColor="orange"
//     changeRating={(val) => setRating(val)}
//     numberOfStars={5}
//     name='rating'
//     starDimension={30}
//     starSpacing={1}
//     style={{ width: 100 }}
//   />
// </div>

// { importSource === 'youtube' &&
//   <p className="small muted">Open the YouTube video in a browser window. Copy the URL from the address bar. You can also click Share & then Copy.</p>
// }
// { importSource === 'twitter' &&
//   <p className="small muted">Open a Twitter tweet in a browser window. Copy the link to the tweet from the address bar.</p>
// }
// { importSource === 'linkedin' &&
//   <p className="small muted">The url must be be retrieved from the "Embed this post" option for the desired post. Use the "src" attribute of the iframe from the embed code of the post.</p>
// }
//
// { importSource === 'youtube' && importUrl &&
//   <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//     <YouTubeEmbed url={importUrl} width={'100%'} height={220} />
//   </div>
// }
// { importSource === 'twitter' && importUrl &&
//   <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//     <TwitterEmbed url={importUrl} width={'100%'} />
//   </div>
// }
// { importSource === 'linkedin' && importUrl &&
//   <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
//     <LinkedInEmbed url={importUrl} width={'100%'} />
//   </div>
// }

// Upload photos and quotes separately
// { (showcase.videoContent || showcase.photoContent) &&
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setUploadModal(true)}><UploadSimple size={16} style={{ marginRight: 5 }} /> Upload</p>
// }
// <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setImportModal(true)}><DownloadSimple size={16} style={{ marginRight: 5 }} /> Import</p>
// { showcase.quoteContent &&
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setQuoteModal(true)}><Quotes size={16} style={{ marginRight: 5 }} /> Quote</p>
// }
// <div>
//   <h6 style={{ marginTop: 20 }}>Upload a photo</h6>
//   <InputGroup className="mb-3" style={{ marginTop: 10 }}>
//     <Form.Control type="file" onChange={handlePhotoChange} style={{ border: 0, padding: 0, backgroundColor: '#FFFFFF' }}/>
//   </InputGroup>
//   { photoError &&
//     <p className="text-danger">Upload PNGs/JPGs less than 2MB in size</p>
//   }
//   { photoProgress > 0 && photoProgress < 100 &&
//     <p className="small muted">Uploading ... { parseInt(photoProgress) }%</p>
//   }
//   { photoUrl &&
//     <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
//       <Image src={ photoUrl } style={{ maxHeight: 100, objectFit: 'cover' }} />
//     </Row>
//   }
// </div>

// <Modal
//   show={quoteModal}
//   centered
//   size="md"
//   fullscreen='lg-down'
//   style={{ backgroundColor: '#111111AA' }}
//   onHide={() => setQuoteModal(false)}>
//   <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
//     <h4>Quote</h4>
//     <Form noValidate validated={validated} onSubmit={handleQuoteValidation} style={{ marginTop: 20 }}>
//       <Form.Label style={{ marginTop: 10, marginBottom: 5 }}>
//         <h6>Quote</h6>
//         <p className="small muted">Enter the quote text.</p>
//       </Form.Label>
//       <Form.Group controlId="url">
//         <Form.Control required as="textarea" type="text" minLength="3" maxLength="500" value={quote} onChange={(e) => setQuote(e.target.value)} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid text 3 to 500 characters long
//         </Form.Control.Feedback>
//       </Form.Group>
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>Name</h6>
//       </Form.Label>
//       <Form.Group controlId="name">
//         <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Ava Ko" value={name} onChange={(e) => setName(e.target.value)} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid name 3 to 96 characters in length
//         </Form.Control.Feedback>
//       </Form.Group>
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>Job title</h6>
//       </Form.Label>
//       <Form.Group controlId="jobtitle">
//         <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Director of Marketing" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid job title 3 to 96 characters in length
//         </Form.Control.Feedback>
//       </Form.Group>
//       <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//         <h6>LinkedIn Handle</h6>
//       </Form.Label>
//       <Form.Group controlId="linkedin">
//         <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={linkedinUsername} onChange={(e) => setLinkedinUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
//         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//         <Form.Control.Feedback type="invalid">
//           Enter a valid LinkedIn handle up to 96 characters long
//         </Form.Control.Feedback>
//       </Form.Group>
//       <h6 style={{ marginTop: 20, marginBottom: 5 }}>Tags</h6>
//       <div style={{ backgroundColor: 'transparent' }}>
//         <ReactTags
//           tags={postTags}
//           suggestions={suggestions}
//           delimiters={delimiters}
//           handleDelete={handleDelete}
//           handleAddition={handleAddition}
//           handleDrag={handleDrag}
//           handleTagClick={handleTagClick}
//           inline={true}
//           placeholder={"+ Add tags"}
//           inputFieldPosition="inline"
//           autofocus={false}
//           autocomplete
//         />
//       </div>
//       <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
//         <Button variant="tertiary" onClick={() => setQuoteModal(false)}>Cancel</Button>
//         <Button variant="primary" type="submit" style={{ marginTop: 20 }} disabled={!quote || quote.length === 0 ? true : false }>Save</Button>
//       </Row>
//     </Form>
//   </Modal.Body>
// </Modal>

// const saveQuoteAsPost = () => {
//   let newUrlPost = {
//     id: generateRandomString(16),
//     showcaseId: showcase.id,
//     workspaceId: showcase.workspaceId,
//     name: name,
//     jobTitle: jobTitle,
//     linkedinUsername: linkedinUsername,
//     quote: quote,
//     type: 'quote',
//     approved: true,
//     publicVisibility: true,
//     createdTs: Date.now(),
//     visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
//   };
//   addDoc(collection(db, "posts"), newUrlPost).then(() => {
//     doToastSuccess("Quote saved!");
//     setQuote(null);
//     setQuoteModal(false);
//     window.location.reload(true);
//   })
// };

//
// const handleQuoteValidation = (event) => {
//   if (event) {
//     const form = event.currentTarget;
//     event.preventDefault();
//     event.stopPropagation();
//     if (form.checkValidity() === true) {
//       saveQuoteAsPost();
//     }
//     setValidated(true);
//   }
// };
//
// const handleVideoUpload = (video) => {
//   let id = generateRandomString(24);
//   const metadata = { contentType: video.type, customMetadata: { ruleCode: process.env.REACT_APP_FIREBASE_STORAGE_CODE }};
//   const storageRef = ref(storage, 'videos/' + id);
//   const uploadTask = uploadBytesResumable(storageRef, video, metadata);
//   uploadTask.on('state_changed',
//     (snapshot) => {
//       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       // console.log('Upload is ' + progress + '% done');
//       setVideoProgress(progress);
//       switch (snapshot.state) {
//         case 'paused':
//           break;
//         case 'running':
//           break;
//       }
//     },
//     (error) => {
//       console.log("video upload error");
//     },
//     () => {
//       getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
//         setVideoUrl(downloadUrl);
//       });
//     }
//   );
// };
// Vertical links panel
// <Col xs={4} sm={4} md={4} lg={3} xl={3}>
//   <h6>Request Responses</h6>
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/request/" + showcase.gatherCode); doToastSuccess('Request URL copied to clipboard') }}><Copy size={16} style={{ marginRight: 5 }} /> Copy URL</p>
//   { false && <p className="small" style={{ marginBottom: 10, marginLeft: 20, cursor: 'pointer' }} onClick={() => console.log(true)}><Envelope size={16} style={{ marginRight: 5 }} /> Email requests</p> }
//   { showcase.publicVisibility &&
//     <div>
//       <h6 style={{ marginTop: 30 }}>Public page</h6>
//       <a href={"/p/" + showcase.id} target="_blank" rel="noopener noreferrer"><p className="small" style={{ marginTop: 10, cursor: 'pointer' }}><Eye size={16} style={{ marginRight: 5 }} /> Open page</p></a>
//       <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/p/" + showcase.id); doToastSuccess('Public page URL copied to clipboard') }}><Copy size={16} style={{ marginRight: 5 }} /> Copy URL</p>
//     </div>
//   }
//   <h6 style={{ marginTop: 30 }}>Add { showcase.showcaseType }</h6>
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => setUploadModal(true)}><UploadSimple size={16} style={{ marginRight: 5 }} /> Upload</p>
//
//   <h6 style={{ marginTop: 30 }}>Manage showcase</h6>
//   <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => navigate('/showcases/edit/' + showcase.id)}><PencilSimple size={16} style={{ marginRight: 5 }} /> Edit</p>
//
//   { showcase.publicVisibility &&
//     <div>
//       <h6 style={{ marginTop: 30 }}>Embed showcase</h6>
//       <p className="small" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => { setEmbedModal(true) }}><CodeSimple size={16} style={{ marginRight: 5 }} /> Copy code</p>
//     </div>
//   }
// </Col>

// { videoUrl &&
//   <div style={{ marginTop: 20 }}>
//     <ReactPlayer
//         id="1"
//         url={videoUrl}
//         width="100%"
//         height="200px"
//         controls={true}
//         volume={1}
//         muted={false}
//     />
//   </div>
// }

// { posts.length > 0 && false &&
//   <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
//     {
//       posts.map((p, id) => {
//         if (filterTag === 'All' || !p.tags || p.tags.some(t => t.text === filterTag)) {
//           return (
//             <Col xs={12} sm={11} md={6} lg={6} xl={6} style={{ padding: 10 }}>
//               <PostCard post={p} collection />
//             </Col>
//           )
//         }
//       })
//     }
//   </Row>
// }
