import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { db } from "./use-firebase.js";
import { collection, getDocs, where, query, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Table, Spinner, Image, Card } from 'react-bootstrap';
import { GlobeSimple, TwitterLogo, FacebookLogo, InstagramLogo, LinkedinLogo, SortAscending, SortDescending, CaretRight, CaretLeft } from "phosphor-react";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { XMasonry, XBlock } from "react-xmasonry";
import PostCard from "./components/cards/Post.js";
import { Helmet } from "react-helmet";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export default function PublicPage() {

  const navigate = useNavigate();
  const props = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // console.log("params", searchParams.get("format"));

  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [showcase, setShowcase] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [filterTag, setFilterTag] = useState('All');
  const [postOrder, setPostOrder] = useState('ascending');
  const carouselRef = useRef(null);

  useEffect(() => {
    // get showcase
    if (props === undefined || props.id === undefined) {
      navigate('/notfound');
    } else {
      getDocs(query(collection(db, "showcases"), where("id", "==", props.id), where("publicVisibility", "==", true), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            setShowcase(doc.data());
            // get posts
            getDocs(query(collection(db, "posts"), where("approved", "==", true), where("publicVisibility", "==", true), where("showcaseId", "==", doc.data().id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot2) => {
              let _posts = [];
              let _tags = [];
              querySnapshot2.forEach((doc2) => {
                _posts.push(doc2.data());
                if (doc2.data().tags) {
                  doc2.data().tags.forEach((t, i) => {
                    if (!_tags.includes(t.text)) {
                      _tags.push(t.text);
                    }
                  });
                }
              });
              setTags(_tags);
              if (props && props.embed && props.embed) {
                setPosts(_posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1).splice(0,8));
              } else {
                setPosts(_posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1));
              }
            });
            // get workspace
            getDocs(query(collection(db, "workspaces"), where("id", "==", doc.data().workspaceId), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot3) => {
              querySnapshot3.forEach((doc3) => {
                setWorkspace(doc3.data());
              });
            });
            // record view
            if (window.location.hostname !== 'localhost') {
              addDoc(collection(db, "views"), { showcaseId: props.id, workspaceId: doc.data().workspaceId, viewTs: Date.now(), visibility: process.env.REACT_APP_FIREBASE_DB_CODE });
            }
          });

        } else {
          navigate('/notfound');
        }
      });
    }
    window.scrollTo(0,0);
  }, [props]);

  const scroll = (scrollOffset) => {
    carouselRef.current.scrollLeft += scrollOffset;
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (!showcase || !workspace) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  if (props && props.embed && props.embed === 'embed' && searchParams.get("layout") === 'carousel') {
    return (
      <div>
        <div className="carousel" style={{ display: 'flex', overflowX: 'scroll', overflow: 'hidden', scrollBehavior: 'smooth', marginTop: -40, backgroundColor: 'transparent' }} ref={carouselRef}>
          {
            posts.map((p) => {
              if (filterTag === 'All' || p.tags.some(t => t.text === filterTag)) {
                return (
                  <div style={{ flexBasis: '30%', flexShrink: 0, minWidth: 280 }}>
                    <PostCard post={p} public />
                  </div>
                )
              }
            })
          }
        </div>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 40, width: '100%' }}>
          <CaretLeft size={22} weight="bold" color={'#999999'} style={{ cursor: 'pointer', marginRight: 20 }} onClick={() => scroll(-100)} />
          <CaretRight size={22} weight="bold" color={'#999999'} style={{ cursor: 'pointer', marginLeft: 20 }} onClick={() => scroll(100)} />
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 40, width: '100%', textAlign: 'center' }}>
          { showcase.showcaseType && showcase.showcaseType === 'review' &&
            <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/reviews-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
          }
          { showcase.showcaseType && showcase.showcaseType === 'testimonial' &&
            <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/testimonials-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
          }
          { (!showcase.showcaseType || (showcase.showcaseType !== 'review' && showcase.showcaseType !== 'testimonial')) &&
            <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/content-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
          }
        </Row>
        <div style={{ height: 20 }}></div>
      </div>
    )
  }

  if (props && props.embed && props.embed === 'embed') {
    return (
      <div style={{ width: '100%', marginTop: -40, backgroundColor: 'transparent' }}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 650: 1, 850: 2, 1000: 3 }} style={{ width: '100%', padding: 10 }}>
          <Masonry gutter="0px">
            {
              posts.map((p) => {
                return (
                  <PostCard post={p} public />
                )
              })
            }
          </Masonry>
        </ResponsiveMasonry>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, width: '100%' }}>
          <Col style={{ textAlign: 'center' }}>
            <p className="muted small"><a href={"https://content.nikos.ai/showcases/" + props.id} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>View all</a></p>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20, width: '100%', textAlign: 'center' }}>
          { showcase.showcaseType && showcase.showcaseType === 'review' &&
            <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/reviews-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
          }
          { showcase.showcaseType && showcase.showcaseType === 'testimonial' &&
            <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/testimonials-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
          }
          { (!showcase.showcaseType || (showcase.showcaseType !== 'review' && showcase.showcaseType !== 'testimonial')) &&
            <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/content-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
          }
        </Row>
      </div>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginTop: -20 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ workspace.name }: { showcase.name }</title>
        <meta name="description" content={ showcase.description } />
        <meta property="og:title" content={ workspace.name + ": " + showcase.name } />
        <meta property="og:description" content={ showcase.description } />
        <meta property="og:url" content={"https://app.nikos.ai/p/" + props.id } />
      </Helmet>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
            { workspace && workspace.logoUrl &&
              <Image src={ workspace.logoUrl } style={{ height: 40, objectFit: 'cover' }} />
            }
            { workspace && !workspace.logoUrl &&
              <h2>{ workspace.name }</h2>
            }
            <h3 style={{ marginTop: 30 }}>{ showcase.name }</h3>
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10, width: '100%' }}>
              <Col xs={12} sm={9} md={7} lg={6} xl={5} style={{ textAlign: 'left', padding: 0 }}>
                <p className="small muted">{ showcase.description }</p>
              </Col>
            </Row>
          </Row>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 10, marginLeft: 0, marginRight: 0, width: '100%' }}>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0, marginBottom: 10 }}>
              { (workspace.webUrl || workspace.linkedinHandle || workspace.twitterHandle || workspace.facebookHandle || workspace.instagramHandle) &&
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                  { workspace.webUrl &&
                    <a href={workspace.webUrl} target="_blank" rel="noopener noreferrer"><GlobeSimple size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.linkedinHandle &&
                    <a href={'https://www.linkedin.com/company/' + workspace.linkedinHandle} target="_blank" rel="noopener noreferrer"><LinkedinLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.twitterHandle &&
                    <a href={'https://www.twitter.com/' + workspace.twitterHandle} target="_blank" rel="noopener noreferrer"><TwitterLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.facebookHandle &&
                    <a href={'https://www.facebook.com/' + workspace.facebookHandle} target="_blank" rel="noopener noreferrer"><FacebookLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                  { workspace.instagramHandle &&
                    <a href={'https://www.instagram.com/' + workspace.instagramHandle} target="_blank" rel="noopener noreferrer"><InstagramLogo size={26} weight="bold" style={{ marginRight: 15 }} /></a>
                  }
                </Row>
              }
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 0 }}>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                <p className="small muted" style={{ marginRight: 10 }}>Share</p>
                <EmailShareButton subject={"Check out " + showcase.name} body={"I thought you would find this interesting. They are " + showcase.name + " from " + workspace.name} source="Nikos" url={"https://app.nikos.ai/p/" + props.id} style={{ marginRight: 10 }}>
                    <EmailIcon size={30} round={true} />
                </EmailShareButton>
                <LinkedinShareButton title={"Check out " + showcase.name + " from " + workspace.name} summary={showcase.description} source="Nikos" url={"https://app.nikos.ai/p/" + props.id} style={{ marginRight: 10 }}>
                    <LinkedinIcon size={30} round={true} />
                </LinkedinShareButton>
                <FacebookShareButton quote={"Check out " + showcase.name + " from " + workspace.name} summary="" source="Nikos" url={"https://app.nikos.ai/p/" + props.id} style={{ marginRight: 10 }}>
                    <FacebookIcon size={30} round={true} />
                </FacebookShareButton>
                <WhatsappShareButton title={"Check out " + showcase.name + " from " + workspace.name} url={"https://app.nikos.ai/p/" + props.id} style={{ marginRight: 10 }}>
                    <WhatsappIcon size={30} round={true} />
                </WhatsappShareButton>
                <TwitterShareButton title={"Check out " + showcase.name + " from " + workspace.name} url={"https://app.nikos.ai/p/" + props.id} style={{ marginRight: 10 }}>
                    <TwitterIcon size={30} round={true} />
                </TwitterShareButton>
              </Row>
            </Col>
          </Row>
          { tags.length > 0 &&
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginLeft: 0, marginRight: 0 }}>
              <Col xs={10} sm={10} md={10} lg={11} xl={11} style={{ paddingLeft: 0 }}>
                <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                  <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: filterTag === "All" ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag('All')}>All</p>
                  {
                    tags.map((t, id) => {
                      return (
                        <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: t == filterTag ? 'lightgreen' : '#eeeeee', padding: '5px 15px', cursor: 'pointer' }} onClick={() => setFilterTag(t)}>#{ t }</p>
                      )
                    })
                  }
                </Row>
              </Col>
              { false &&
                <Col xs={2} sm={2} md={2} lg={1} xl={1} style={{ textAlign: 'right' }}>
                  { postOrder === 'ascending' &&
                    <SortAscending size={22} color={"#999999"} style={{ cursor: 'pointer' }} onClick={() => { setPostOrder('descending'); setPosts(posts.sort((a, b) => (a.createdTs > b.createdTs) ? 1 : -1)); }} />
                  }
                  { postOrder === 'descending' &&
                    <SortDescending size={22} color={"#999999"} style={{ cursor: 'pointer' }} onClick={() => { setPostOrder('ascending'); setPosts(posts.sort((a, b) => (b.createdTs > a.createdTs) ? 1 : -1)); }} />
                  }
                </Col>
              }
            </Row>
          }
          <ResponsiveMasonry columnsCountBreakPoints={{ 650: 1, 850: 2, 1000: 3 }} style={{ width: '100%', padding: 10 }}>
            <Masonry gutter="0px">
              {
                posts.map((p) => {
                  if (filterTag === 'All' || (p.tags && p.tags.some(t => t.text === filterTag))) {
                    return (
                      <PostCard post={p} public />
                    )
                  }
                })
              }
            </Masonry>
          </ResponsiveMasonry>
        </Col>
      </Row>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 60, marginBottom: 60, width: '100%', textAlign: 'center' }}>
        { showcase.showcaseType && showcase.showcaseType === 'review' &&
          <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/reviews-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
        }
        { showcase.showcaseType && showcase.showcaseType === 'testimonial' &&
          <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/testimonials-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
        }
        { (!showcase.showcaseType || (showcase.showcaseType !== 'review' && showcase.showcaseType !== 'testimonial')) &&
          <a href="https://www.nikos.ai" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}><Image src="/assets/badges/content-powered-by-nikos.svg" width="160" alt="Nikos Logo" style={{ marginTop: 3 }} /></a>
        }
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}


// <XMasonry maxColumns={6} responsive="true" targetBlockWidth={160}>
//     {
//       posts.map((p) => {
//         if (filterTag === 'All' || p.tags.some(t => t.text === filterTag)) {
//           return (
//             <XBlock key={ p.id } width={2}>
//               <PostCard post={p} public />
//             </XBlock>
//           )
//         }
//       })
//     }
// </XMasonry>

// <ResponsiveMasonry columnsCountBreakPoints={{ 650: 1, 850: 2, 1000: 3 }} style={{ width: '100%', padding: 20 }}>
//   <Masonry gutter="20px">
//     {
//       posts.map((p) => {
//         if (filterTag === 'All' || p.tags.some(t => t.text === filterTag)) {
//           return (
//             <PostCard post={p} public />
//           )
//         }
//       })
//     }
//   </Masonry>
// </ResponsiveMasonry>
