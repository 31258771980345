import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail, useMediaQuery, generateRandomString } from './shared-functions.js';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { collection, getDocs, where, query, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Table, Spinner, Modal, Form, InputGroup } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { Copy, Eye } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';
import { bootIntercom, updateIntercom } from './shared-intercom.js';
import CommonHelmet from "./components/common/Helmet.js";

export default function Members() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [members, setMembers] = useState([]);
  const [inviteModal, setInviteModal] = useState(false);
  const [validated, setValidated] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      // get members
      getDocs(query(collection(db, "members"), where("workspaceId", "==", auth.workspace.id))).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          let _members = []
          querySnapshot.forEach((doc) => {
            _members.push(doc.data());
          });
          setMembers(_members);
          // update intercom with number of workspace members
          updateIntercom(auth.user.uid, 'number_of_workspace_members', _members.length);
        }
      });
      // boot intercom
      bootIntercom(auth.user.uid, auth.user.name, auth.user.email, auth.user.createdTs);
    }
    window.scrollTo(0,0);
  }, [auth]);

  const handleValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        saveInvite();
      }
      setValidated(true);
    }
  };

  const saveInvite = () => {
    let newMemberInvitation = {
      id: generateRandomString(16),
      workspaceId: auth.workspace.id,
      invitedBy: auth.user.uid,
      name: name,
      email: email,
      role: 'Member',
      joined: false,
      declined: false,
      memberId: null,
      createdTs: Date.now(),
      joinedTs: null,
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "members"), newMemberInvitation).then(() => {
      doToastSuccess("Invitation sent!");
      setName(null);
      setEmail(null);
      setInviteModal(false);
      setMembers([members, ...newMemberInvitation]);
    })
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (!auth || !auth.user || !auth.workspace) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <CommonHelmet />
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={6} lg={8} xl={8} style={{ padding: 5 }}>
              <h3>Members</h3>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4} style={{ padding: 5, textAlign: 'right' }}>
              <Button variant="primary" onClick={() => setInviteModal(true)}>+ Invite</Button>
            </Col>
          </Row>
          { members.length === 0 &&
            <p style={{ marginTop: 10 }}>None yet</p>
          }
          { members.length > 0 &&
            <Table borderless responsive="sm" striped variant="light" style={{ marginTop: 10, backgroundColor: '#FFFFFF' }}>
              <thead>
                <tr style={{ fontFamily: 'Manrope SemiBold' }}>
                  <th>#</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  members.map((w, id) => {
                    return (
                      <tr key={id}>
                        <td>{ id + 1 }</td>
                        <td>{ w.name }</td>
                        <td><h6>{ w.role }</h6></td>
                        <td><p className="small">{ w.joined ? <Moment fromNow>{ w.joinedTs }</Moment> : 'Pending invitation' }</p></td>
                        <td></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
      <Modal
        show={inviteModal}
        centered
        size="md"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setInviteModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h4>Invite a member</h4>
          <Form noValidate validated={validated} onSubmit={handleValidation} style={{ marginTop: 20 }}>
            <Form.Label style={{ marginTop: 10, marginBottom: 5 }}>
              <h6>Name</h6>
            </Form.Label>
            <Form.Group controlId="name">
              <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Ava Ko" value={name} onChange={(e) => setName(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter a valid name 3 to 96 characters in length
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>Email</h6>
            </Form.Label>
            <Form.Group controlId="email">
              <Form.Control required as="input" type="email" minLength="3" maxLength="96" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter a valid email
              </Form.Control.Feedback>
            </Form.Group>
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="tertiary" onClick={() => setInviteModal(false)}>Cancel</Button>
              <Button variant="primary" type="submit" style={{ marginTop: 20 }} disabled={!email || !validateEmail(email) ? true : false }>Send</Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  );

}
