import React from "react";
import mixpanel from 'mixpanel-browser';

const DEBUG = false;

export function mixpanelUserProfileSet(uid, name, email, created) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, { debug: DEBUG, ignore_dnt: true });
  // mixpanel.identify(uid);
  // mixpanel.alias(email, uid);
  mixpanel.people.set({ $name: name, $email: email, $created: created });
  return null;
}

export function mixpanelUserPropertyUpdate(uid, property, value) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, { debug: DEBUG, ignore_dnt: true });
  // mixpanel.identify(uid);
  mixpanel.people.set(property, value);
  return null;
}

export function mixpanelLogin(uid) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, { debug: DEBUG, ignore_dnt: true });
  mixpanel.identify(uid);
  return null;
}

export function mixpanelTrackSignup(uid, workspace_id) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, { debug: DEBUG, ignore_dnt: true });
  mixpanel.identify(uid);
  mixpanel.track('signed up', {
    distinct_id: uid,
    workspace_id: workspace_id
  });
  return null;
}

export function mixpanelTrackShowcaseCreate(uid, workspace_id, template_id) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, { debug: DEBUG, ignore_dnt: true });
  mixpanel.track('created showcase', {
    distinct_id: uid,
    workspace_id: workspace_id,
    template_id: template_id,
  });
  return null;
}

export function mixpanelTrackSelfUpload(uid, workspace_id, showcase_id, content_type) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, { debug: DEBUG, ignore_dnt: true });
  mixpanel.track('self upload', {
    distinct_id: uid,
    workspace_id: workspace_id,
    showcase_id: showcase_id,
    content_type: content_type,
  });
  return null;
}
