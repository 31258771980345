import React, { useState, useEffect, useContext, createContext } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where, addDoc, setDoc, doc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { getStorage } from 'firebase/storage';
import { generateRandomString } from './shared-functions.js';
import { getAuth, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged, signOut, GoogleAuthProvider } from "firebase/auth";
import { mixpanelTrackSignup, mixpanelLogin } from './shared-mixpanel.js';

// STAGING
// const app = initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_STAGING_API_KEY,
//   authDomain:  process.env.REACT_APP_FIREBASE_STAGING_AUTH_DOMAIN,
//   projectId:  process.env.REACT_APP_FIREBASE_STAGING_PROJECT_ID,
//   storageBucket:  process.env.REACT_APP_FIREBASE_STAGING_STORAGE_BUCKET,
//   messagingSenderId:  process.env.REACT_APP_FIREBASE_STAGING_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_STAGING_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_STAGING_MEASUREMENT_ID,
// });

// PRODUCTION
const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain:  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket:  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();
const provider = new GoogleAuthProvider();

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {

  const [user, setUser] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [authenticating, setAuthenticating] = useState(false);

  const signout = () => {
    signOut(auth).then(() => {
      setUser(false);
      shutdownIntercom();
    }).catch((error) => {
      // An error happened.
    });
  };

  const googleAuthenticate = () => {
    setAuthenticating(true);
    return signInWithPopup(auth, provider)
      .then(function(response) {
        let newuser = {
          uid: response.user.uid,
          name: response.user.displayName,
          email: response.user.email,
          emailVerified: response.user.emailVerified,
          phoneNumber: response.user.phoneNumber,
          avatarUrl: response.user.photoURL,
          visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
          createdTs: Date.now(),
        }
        getDocs(query(collection(db, "users"), where("uid", "==", response.user.uid))).then((querySnapshot) => {
          if (querySnapshot.size > 0) {
            querySnapshot.forEach(function(_doc) {
              let existinguser = _doc.data();
              setUser(existinguser);
              // mixpanel identify user
              mixpanelLogin(existinguser.uid);
              // set authenticating to false
              setAuthenticating(false);
              // get workspace
              getDocs(query(collection(db, "workspaces"), where("id", "==", existinguser.workspaceId))).then((querySnapshot2) => {
                querySnapshot2.forEach(function(doc2) {
                  setWorkspace(doc2.data());
                })
              });
            });
          } else {
            newuser.handle = generateRandomString(8).toLowerCase();
            newuser.workspaceId = response.user.uid;
            addDoc(collection(db, "users"), newuser).then(() => {
              setUser(newuser);
              // create workspace
              let newWorkspace = {
                id: newuser.uid,
                name: 'My company name',
                createdTs: Date.now(),
                visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
              }
              addDoc(collection(db, "workspaces"), newWorkspace).then(() => {
                setWorkspace(newWorkspace);
              });
              // add member as admin to workspace
              let newMember = {
                id: generateRandomString(16),
                workspaceId: newuser.uid,
                invitedBy: newuser.uid,
                name: newuser.name,
                email: newuser.email,
                role: 'Admin',
                joined: true,
                memberId: newuser.uid,
                createdTs: Date.now(),
                joinedTs: Date.now(),
                visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
              };
              addDoc(collection(db, "members"), newMember).then(() => {

              });
              // mixpanel track new user signup
              mixpanelTrackSignup(newuser.uid, newuser.uid);
              // set authenticating to false
              setAuthenticating(false);
            });
          }
        }).catch(error => {
          setAuthenticating(false);
          return error;
        })
        return response.user
      }).catch(error => {
        setAuthenticating(false);
        return error;
      });
  };

  const signin = (email, password) => {
    // const auth = getAuth();
    // console.log("signing in", email, password);
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // get user
        db.collection("users").where("uid","==",user.uid).get(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            setUser(doc.data());
            // mixpanel identify user
            mixpanelLogin(doc.data().uid);
          });
        });
        // return true;
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        console.log("error", error.code);
        return error;
      });
  }

  const signup = (email, password, name) => {
    // console.log("creating", auth, email, name);
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // create user
        // console.log("user is", user);
        let newuser = {
          uid: userCredential.user.uid,
          name: name,
          email: userCredential.user.email,
          emailVerified: userCredential.user.emailVerified,
          avatarUrl: null,
          handle: generateRandomString(8).toLowerCase(),
          workspaceId: userCredential.user.uid,
          visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
          createdTs: Date.now(),
        }
        addDoc(collection(db, "users"), newuser).then(() => {
          setUser(newuser);
          // create workspace
          let newWorkspace = {
            id: userCredential.user.uid,
            name: 'My company name',
            createdTs: Date.now(),
            visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
          }
          addDoc(collection(db, "workspaces"), newWorkspace).then(() => {
            setWorkspace(newWorkspace);
          });
          // add member as admin to workspace
          let newMember = {
            id: generateRandomString(16),
            workspaceId: userCredential.user.uid,
            invitedBy: userCredential.user.uid,
            name: name,
            email: email,
            role: 'Admin',
            joined: true,
            memberId: userCredential.user.uid,
            createdTs: Date.now(),
            joinedTs: Date.now(),
            visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
          };
          addDoc(collection(db, "members"), newMember);
          // mixpanel track new user signup
          mixpanelTrackSignup(newuser.uid, newuser.uid);
        });
      })
      .catch((error) => {
        console.log("error", error);
        const errorCode = error.code;
        const errorMessage = error.message;
        return error;
      });
  }

  const forgotPassword = email => {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // console.log("sent");
      })
      .catch((error) => {
        console.log("error", error);
        // const errorCode = error.code;
        // const errorMessage = error.message;
        return error;
      });
  };

  const changeWorkspace = (_workspace) => {
    setWorkspace(_workspace);
  };

  const updateUser = (_user) => {
    setUser(_user);
  };

  const shutdownIntercom = () => {
    window.Intercom('shutdown');
  }

  useEffect(() => {
    onAuthStateChanged(auth, (_user) => {
      if (_user) {
        getDocs(query(collection(db, "users"), where("uid", "==", _user.uid))).then((querySnapshot) => {
          querySnapshot.forEach(function(_doc) {
            setUser(_doc.data());
            getDocs(query(collection(db, "workspaces"), where("id", "==", _doc.data().workspaceId))).then((querySnapshot2) => {
              querySnapshot2.forEach(function(doc2) {
                setWorkspace(doc2.data());
              })
            });
          });
        });
      } else {
        setUser(false);
        setWorkspace(null);
        shutdownIntercom();
      }
    });
  }, []);

  return {
    user,
    workspace,
    signin,
    signup,
    signout,
    forgotPassword,
    changeWorkspace,
    updateUser,
    googleAuthenticate,
    authenticating,
  };

};

// if (existinguser.avatarUrl === undefined) {
//   existinguser.avatarUrl = response.user.photoURL
// }
// if (existinguser.name === undefined || existinguser.name.length === 0) {
//   existinguser.name = response.user.displayName
// }
// existinguser.emailVerified = response.user.emailVerified
// existinguser.googleAccessToken = response.credential.accessToken
// setDoc(doc(db, "users", _doc.id), existinguser).then(() => {
//
// });

// const signin = () => {
//   return signInAnonymously(auth)
//     .then((response) => {
//       // console.log("user is", response);
//       setUser(response.user);
//     })
//     .catch((error) => {
//       const errorCode = error.code;
//       const errorMessage = error.message;
//     });
// };

// export const signIn = () => {
//   signInAnonymously(auth)
//   .then((u) => {
//     console.log("u", u);
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//   });
// }

// const app = initializeApp({
//   apiKey: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DEV_API_KEY : process.env.REACT_APP_FIREBASE_API_KEY, // "AIzaSyBVEiwMw59FZQxAcvXnJDanG30u_3PptDg",
//   authDomain:  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, // "nikos-content.firebaseapp.com",
//   projectId:  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID : process.env.REACT_APP_FIREBASE_PROJECT_ID, // "nikos-content",
//   storageBucket:  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, // "nikos-content.appspot.com",
//   messagingSenderId:  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, // "279418998353",
//   appId:  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DEV_APP_ID : process.env.REACT_APP_FIREBASE_APP_ID, // "1:279418998353:web:b0ffc56adbb7452d2e3270",
//   measurementId:  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_FIREBASE_DEV_MEASUREMENT_ID : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, // "G-62V61XJ7T1"
// });
