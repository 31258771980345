import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { getDocs, collection, updateDoc, query, where, doc } from 'firebase/firestore'; // collection, addDoc, getDocs
import { RESERVED_HANDLES } from "./shared-constants.js";
import Footer from './components/common/Footer.js';
import Loading from './components/common/Loading.js';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonHelmet from "./components/common/Helmet.js";

export default function WorkspaceInvite() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const [stepNum, setStepNum] = useState(0);
  const [invite, setInvite] = useState(null);
  const [newWorkspace, setNewWorkspace] = useState(null);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      getDocs(query(collection(db, "members"), where("email", "==", auth.user.email), where("joined", "==", false), where("declined", "==", false))).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((_doc) => {
            setInvite(_doc.data());
            getDocs(query(collection(db, "workspaces"), where("id", "==", _doc.data().workspaceId))).then((querySnapshot2) => {
              querySnapshot2.forEach((_doc2) => {
                setNewWorkspace(_doc2.data());
              });
            });
          });
        } else {
          navigate('/');
        }
      });
    }
  }, [auth]);

  const acceptAndSwitch = () => {
    getDocs(query(collection(db, "members"), where("email", "==", auth.user.email), where("joined", "==", false), where("declined", "==", false))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        updateDoc(doc(db, "members", _doc.id), { joined: true, joinedTs: Date.now(), name: auth.user.name, uid: auth.user.uid }).then(() => {
          saveNewWorkspace();
        });
      });
    });
  };

  const decline = () => {
    getDocs(query(collection(db, "members"), where("email", "==", auth.user.email), where("joined", "==", false), where("declined", "==", false))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        updateDoc(doc(db, "members", _doc.id), { declined: true, declinedTs: Date.now() }).then(() => {
          pauseAndNavigateHome();
        });
      });
    });
  };

  const saveNewWorkspace = () => {
    getDocs(query(collection(db, "users"), where("uid", "==", auth.user.uid))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        updateDoc(doc(db, "users", _doc.id), { workspaceId: newWorkspace.id }).then(() => {
          auth.changeWorkspace(newWorkspace);
          pauseAndNavigateHome();
        });
      });
    });
  };

  const pauseAndNavigateHome = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/');
    }, 2000)
  }

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (!auth || !auth.user || !auth.workspace || !newWorkspace || loading) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40, marginTop: 60 }}>
      <CommonHelmet />
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, minHeight: '60vh' }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <div>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
              <h3>You've been invited to { newWorkspace.name }</h3>
            </Row>
            <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="primary" onClick={() => acceptAndSwitch()}>Accept</Button>
              <p className="small bold muted" style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => decline()}>Decline</p>
            </Row>
          </div>
        </Col>
      </Row>
      <Footer />
    </Row>
  );

}
