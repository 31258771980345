import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Image, Spinner, Card, Modal, Form, Badge } from 'react-bootstrap';
import { numberWithCommas } from "../../shared-functions.js";
import { db } from "../../use-firebase.js";
import { collection, getDocs, where, query, updateDoc, doc, deleteDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import Moment from 'react-moment';
import { ShieldCheck, ShieldSlash, LinkedinLogo, TwitterLogo, InstagramLogo, Eye, EyeSlash, Link, PencilSimple, Trash, Quotes } from "phosphor-react";
import ReactPlayer from 'react-player/lazy';
// import StarRatings from 'react-star-ratings';
import { TwitterEmbed, InstagramEmbed } from 'react-social-media-embed';
import MuxPlayer from '@mux/mux-player-react';
import { WithContext as ReactTags } from 'react-tag-input';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

export default function PostCard(props) {

  const navigate = useNavigate();
  const [localMuxPlaybackId, setLocalMuxPlaybackId] = useState(null);
  const [postModal, setPostModal] = useState(false);
  const [comment, setComment] = useState(null);
  const [approved, setApproved] = useState(false);
  const [publicVisibility, setPublicVisibility] = useState(false);
  const [tags, setTags] = useState([]);
  const [surelyDelete, setSurelyDelete] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const KeyCodes = { comma: 188, enter: 13 }
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  useEffect(() => {
    if (props && props.post) {
      setComment(props.post.comment);
      setApproved(props.post.approved || false);
      setPublicVisibility(props.post.publicVisibility || false);
      setTags(props.post.tags || []);
      if (props.post.muxId) {
        if (props.post.muxPlaybackId) {
          setLocalMuxPlaybackId(props.post.muxPlaybackId);
        } else {
          getMuxPlaybackId(props.post.muxId);
        }
      }
    }
  }, [props]);

  const getMuxPlaybackId = (_id) => {
    getDocs(query(collection(db, "muxvideos"), where("id", "==", _id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        if (_doc.data().status === 'ready' && _doc.data().asset && _doc.data().asset.playback_ids) {
          setLocalMuxPlaybackId(_doc.data().asset.playback_ids[0].id);
          if (!props.public) {
            saveMuxPlaybackIdToPost(_doc.data().asset.playback_ids[0].id);
          }
        }
      });
    });
  };

  const saveMuxPlaybackIdToPost = (_id) => {
    getDocs(query(collection(db, "posts"), where("id", "==", props.post.id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((_doc) => {
        updateDoc(doc(db, "posts", _doc.id), { muxPlaybackId: _id });
      });
    });
  };

  const saveWallsAndTags = () => {
    getDocs(query(collection(db, "posts"), where("workspaceId", "==", props.post.workspaceId), where("id", "==", props.post.id))).then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((_doc) => {
          updateDoc(doc(db, "posts", _doc.id), { comment: comment ? comment : null,
                                                 tags: tags,
                                                 approved: approved,
                                                 publicVisibility: publicVisibility
                                               }).then(() => {
            doToastSuccess("Changes saved!");
            setPostModal(false);
          });
        });
      }
    });
  };

  const handleDeletePost = () => {
    getDocs(query(collection(db, "posts"), where("workspaceId", "==", props.post.workspaceId), where("id", "==", props.post.id))).then((querySnapshot) => {
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((_doc) => {
          deleteDoc(doc(db, "posts", _doc.id)).then(() => {
            doToastSuccess("Post deleted!");
            setSurelyDelete(false);
            setPostModal(false);
            window.location.reload(true);
          });
        });
      }
    });
  }

  const handleDelete = i => {
    let _tags = tags.filter((tag, index) => index !== i)
    setTags(_tags);
  };

  const handleAddition = tag => {
    let _tag = { id: tag.id, text: tag.text.replace(/#/g,'') }
    let newTags = [...tags, _tag];
    setTags(newTags);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = index => {
    // console.log('The tag at index ' + index + ' was clicked');
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (!props || !props.post) {
    return (
      <Row style={{ justifyContent: 'center' }}>
        <Spinner animation="border" role="status" />
      </Row>
    )
  }
  // navigate('/post/' + props.post.id)

  return (
    <Card className="card-shadow" style={{ padding: 0, margin: 10, backgroundColor: '#FFFFFF', border: '1px solid #EEEEEE' }}>
      { localMuxPlaybackId && props.post.type === 'video' &&
        <MuxPlayer
          streamType="on-demand"
          playbackId={localMuxPlaybackId}
          primaryColor="#EEEEEE"
          width="100%"
          metadata={{
            video_id: props.post.muxId,
            video_title: props.post.name
          }}
        />
      }
      { props.post.type === 'photo' &&
        <Image src={props.post.photoUrl} style={{ objectFit: 'cover', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
      }
      { props.post.type === 'url' && props.post.source === 'twitter' && props.post.url && props.post.url.length > 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', border: 'none', padding: 10 }}>
          <TwitterEmbed url={props.post.url} width="100%" />
        </div>
      }
      { props.post.type === 'url' && props.post.source === 'instagram' && props.post.url && props.post.url.length > 0 &&
        <div style={{ display: 'flex', justifyContent: 'center', border: 'none', padding: 10 }}>
          <InstagramEmbed url={props.post.url} width="100%" captioned />
        </div>
      }
      { props.public && props.post.type === 'url' &&
        <div style={{ marginTop: -10 }}></div>
      }
      { !(props.public && props.post.type === 'url') &&
        <Card.Body style={{ padding: 20 }}>
          { props.post.comment &&
            <div style={{ marginBottom: 10, cursor: 'pointer' }}>
              <Quotes size={20} color="#05c29c" weight="fill" />
              <h5 style={{ marginTop: 3 }} onClick={() => { if (!props.public) { setPostModal(true) }}}>{ props.post.comment }</h5>
            </div>
          }
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: -15 }}>
            <Col style={{ padding: 0 }}>
              { props.post.type !== 'url' &&
                <div>
                  <h6 style={{ marginTop: 20, marginRight: 8 }}>{ props.post.name }</h6>
                  <p className="small muted" style={{ marginTop: 2 }}>{ props.post.jobTitle }</p>
                  <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                    { props.post.linkedinUsername &&
                      <a href={'https://linkedin.com/in/' + props.post.linkedinUsername} target="_blank" rel="noopener noreferrer"><LinkedinLogo weight="bold" size={18} style={{ marginRight: 8 }} /></a>
                    }
                    { props.post.twitterUsername &&
                      <a href={'https://twitter.com/' + props.post.twitterUsername} target="_blank" rel="noopener noreferrer"><TwitterLogo weight="bold" size={18} style={{ marginRight: 8 }} /></a>
                    }
                    { props.post.instagramUsername &&
                      <a href={'https://instagram.com/' + props.post.instagramUsername} target="_blank" rel="noopener noreferrer"><InstagramLogo weight="bold" size={18} /></a>
                    }
                  </Row>
                  <p className="tiny muted" style={{ marginTop: 5 }}><Moment fromNow>{ props.post.createdTs }</Moment></p>
                </div>
              }
              { tags.length > 0 &&
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
                  {
                    tags.map((tag) => {
                      return (
                        <p className="small muted" style={{ marginRight: 10, marginBottom: 5 }}>#{ tag.text.toLowerCase() }</p>
                      )
                    })
                  }
                </Row>
              }
            </Col>
          </Row>
          { !props.public && props.post.type !== 'url' &&
            <hr />
          }
          { !props.public &&
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
              <a><p className="small" style={{ cursor: 'pointer', marginRight: 10 }} onClick={() => { if (!props.public) { setPostModal(true) }}}><PencilSimple size={14} /> Edit</p></a>
              <div>
                { approved &&
                  <ShieldCheck size={18} weight="bold" color={"green"} style={{ marginRight: 10 }} />
                }
                { !approved &&
                  <ShieldSlash size={18} weight="bold" color={"#AAAAAA"} style={{ marginRight: 10 }} />
                }
                { publicVisibility &&
                  <Eye size={18} weight="bold" color={"green"} />
                }
                { !publicVisibility &&
                  <EyeSlash size={18} weight="bold" color={"#AAAAAA"} />
                }
              </div>
            </Row>
          }
          { !props.public && props.post.type !== 'url' &&
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
              <Link size={18} color={'#111111'} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://content.nikos.ai/posts/" + props.post.id); doToastSuccess('Link copied to clipboard') }} />
              <EmailShareButton subject={"Check out this post!"} body={"I think you will find this interesting!"} source="Nikos" url={"https://content.nikos.ai/posts/" + props.post.id} style={{ marginRight: 10 }}>
                  <EmailIcon size={22} round={true} />
              </EmailShareButton>
              <LinkedinShareButton title={"Check this out!"} summary={props.post.comment} source="Nikos" url={"https://content.nikos.ai/posts/" + props.post.id} style={{ marginRight: 10 }}>
                  <LinkedinIcon size={22} round={true} />
              </LinkedinShareButton>
              <FacebookShareButton quote={"Check this out!"} summary={props.post.comment} source="Nikos" url={"https://content.nikos.ai/posts/" + props.post.id} style={{ marginRight: 10 }}>
                  <FacebookIcon size={22} round={true} />
              </FacebookShareButton>
              <WhatsappShareButton title={"Check this out!"} url={"https://content.nikos.ai/posts/" + props.post.id} style={{ marginRight: 10 }}>
                  <WhatsappIcon size={22} round={true} />
              </WhatsappShareButton>
              <TwitterShareButton title={"Check this out!"} url={"https://content.nikos.ai/posts/" + props.post.id} style={{ marginRight: 10 }}>
                  <TwitterIcon size={22} round={true} />
              </TwitterShareButton>
            </Row>
          }
        </Card.Body>
      }
      <Modal
        show={postModal}
        centered
        size="lg"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setPostModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <h4>Edit post</h4>
          <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              { localMuxPlaybackId && props.post.type === 'video' &&
                <div>
                  <MuxPlayer
                    streamType="on-demand"
                    playbackId={localMuxPlaybackId}
                    primaryColor="#CCCCCC"
                    width="80%"
                    metadata={{
                      video_id: props.post.muxId,
                      video_title: props.post.name
                    }}
                  />
                </div>
              }
              { !localMuxPlaybackId && props.post.type === 'video' &&
                <Card style={{ marginTop: 20, backgroundColor: '#eeeeee', height: 300, cursor: 'pointer' }}>
                  <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                    <h5 className="muted">No video</h5>
                  </Row>
                </Card>
              }
              { props.post.type === 'photo' &&
                <Image src={ props.post.photoUrl } style={{ marginTop: 20, objectFit: 'contain', width: '100%', maxHeight: 300 }} />
              }
              <Form style={{ marginTop: 10 }}>
                { props.post.type !== 'url' &&
                  <div>
                    <Form.Label style={{ marginBottom: 5 }}>
                      <h6>Comment</h6>
                    </Form.Label>
                    <Form.Group controlId="name">
                      <Form.Control as="textarea" type="text" maxLength="500" rows={3} value={comment} onChange={(e) => setComment(e.target.value)} />
                    </Form.Group>
                  </div>
                }
                { props.post.type === 'url' && props.post.source === 'twitter' && props.post.url && props.post.url.length > 0 &&
                  <div style={{ display: 'flex', justifyContent: 'center', border: 'none' }}>
                    <TwitterEmbed url={props.post.url} maxHeight="400px" />
                  </div>
                }
                { props.post.type === 'url' && props.post.source === 'instagram' && props.post.url && props.post.url.length > 0 &&
                  <div style={{ display: 'flex', justifyContent: 'center', border: 'none' }}>
                    <InstagramEmbed url={props.post.url} maxHeight="400px" />
                  </div>
                }
                <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6>Moderation</h6>
                    <p className="small muted">By checking of this box you approve this post for viewing on public pages if it were made publicly visible.</p>
                    <Form.Check type="checkbox" id="moderation-switch" label="Approve" checked={approved} style={{ marginTop: 5 }} onChange={(e) => { setApproved(e.target.checked); }} />
                    <h6 style={{ marginTop: 20 }}>Public visibility</h6>
                    <p className="small muted">By checking of this box you make this post publicly visible on public pages of the showcase it is on.</p>
                    <Form.Check type="checkbox" id="visibility-switch" label="Public" checked={publicVisibility} style={{ marginTop: 5 }} onChange={(e) => { setPublicVisibility(e.target.checked); }} />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h6 style={{ marginBottom: 5 }}>Tags</h6>
                    <div style={{ backgroundColor: 'transparent', width: '100%' }}>
                      <ReactTags
                        tags={tags}
                        suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        handleTagClick={handleTagClick}
                        inline={true}
                        placeholder={"+ Add tags"}
                        inputFieldPosition="inline"
                        autofocus={false}
                        autocomplete
                      />
                    </div>
                    <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                      <h6>Created</h6>
                    </Form.Label>
                    <p className="bold" style={{ marginTop: 0 }}>{ props.post.name }</p>
                    <p className="small muted">{ props.post.jobTitle }</p>
                    <p className="tiny muted">on <Moment format="ddd MMM DD, YYYY">{ props.post.createdTs }</Moment></p>
                  </Col>
                </Row>
                { !surelyDelete &&
                  <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 40, marginBottom: 20, marginLeft: 0, marginRight: 0 }}>
                    <Button variant="tertiary" onClick={() => setPostModal(false)}>Close</Button>
                    <Button variant="danger" onClick={() => setSurelyDelete(true)}><Trash size={16} /></Button>
                    <Button variant="secondary" onClick={() => saveWallsAndTags()}>Save changes</Button>
                  </Row>
                }
                { surelyDelete &&
                  <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 40, marginBottom: 20, marginLeft: 0, marginRight: 0 }}>
                    <Button variant="tertiary" onClick={() => setSurelyDelete(false)}>Cancel</Button>
                    <p className="text-danger" style={{ cursor: 'pointer' }}>Are you sure?</p>
                    <Button variant="danger" onClick={() => handleDeletePost()}>Yes, delete</Button>
                  </Row>
                }
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Card>
  );

}

// { props.post.url && props.post.url.length > 0 && false &&
//   <div style={{ marginBottom: 10 }}>
//     <ReactPlayer
//         id={"id-" + props.post.id}
//         url={props.post.url}
//         width="100%"
//         controls
//         volume={1}
//     />
//   </div>
// }
// { props.post.rating > 0 &&
//   <div style={{ marginTop: 10, marginBottom: 10 }}>
//     <StarRatings
//       rating={props.post.rating}
//       starRatedColor="orange"
//       numberOfStars={5}
//       name='rating'
//       starDimension={18}
//       starSpacing={0.5}
//     />
//   </div>
// }

// <Form.Label style={{ marginBottom: 5 }}>
//   <h6>Rating</h6>
// </Form.Label>
// <div>
//   <StarRatings
//     rating={props.post.rating}
//     starRatedColor="orange"
//     numberOfStars={5}
//     name='rating'
//     starDimension={20}
//     starSpacing={1}
//     style={{ width: 100 }}
//   />
// </div>

// { props.post.type === 'photo' && !props.collection &&
//   <Card.Img variant="top" src={props.post.url} style={{ objectFit: 'cover', borderRadius: 8 }} />
// }
// { props.post.type === 'photo' && props.collection &&
//   <Card.Img variant="top" src={props.post.url} style={{ objectFit: 'cover', height: 200, borderRadius: 8 }} />
// }
// { props.post.type === 'url' && props.post.source === 'youtube' && props.post.url && props.post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center' }}>
//     <YouTubeEmbed url={props.post.url} width="100%" height={'340px'} />
//   </div>
// }
// { props.post.type === 'url' && props.post.source === 'twitter' && props.post.url && props.post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center', border: 'none' }}>
//     <TwitterEmbed url={props.post.url} width="100%" />
//   </div>
// }
// { props.post.type === 'url' && props.post.source === 'linkedin' && props.post.url && props.post.url.length > 0 &&
//   <div style={{ display: 'flex', justifyContent: 'center', padding: 5, border: '1px solid #cccccc', borderRadius: 10 }}>
//     <LinkedInEmbed url={props.post.url} width="100%" />
//   </div>
// }
// { props.post.type === 'video' && props.post.url && props.post.url.length > 0 &&
//   <ReactPlayer
//       id={"id-" + props.post.id}
//       url={props.post.url}
//       width="100%"
//       controls
//       volume={1}
//   />
// }

// if (props.post.type === 'quote') {
//   return (
//     <div style={{ padding: 20, cursor: 'pointer' }}>
//       { props.post.rating && props.post.rating > 0 &&
//         <div style={{ marginBottom: 20 }}>
//           <StarRatings
//             rating={props.post.rating}
//             starRatedColor="orange"
//             numberOfStars={5}
//             name='rating'
//             starDimension={24}
//             starSpacing={1}
//           />
//         </div>
//       }
//       <h4 onClick={() => { if (!props.public) { navigate('/post/' + props.post.id) }} }>"{ props.post.quote }"</h4>
//       <h6 style={{ marginTop: 20 }}>{ props.post.name } { props.post.linkedinUsername && <a href={'https://linkedin.com/in/' + props.post.linkedinUsername} target="_blank" rel="noopener noreferrer"><LinkedinLogo size={22} color={"blue"} /></a> }</h6>
//       <p className="small" style={{ marginTop: 2 }}>{ props.post.jobTitle }</p>
//       <p className="small muted" style={{ marginTop: 0 }}><Moment fromNow>{ props.post.createdTs }</Moment></p>
//       { props.post.tags &&
//         <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
//           {
//             props.post.tags.map((tag) => {
//               return (
//                 <p className="small muted" style={{ marginRight: 10 }}>#{ tag.text }</p>
//               )
//             })
//           }
//         </Row>
//       }
//       { !props.public &&
//         <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
//           { props.post.approved &&
//             <ShieldCheck size={18} weight="bold" color={"green"} style={{ marginRight: 10 }} />
//           }
//           { !props.post.approved &&
//             <ShieldSlash size={18} weight="bold" color={"#AAAAAA"} style={{ marginRight: 10 }} />
//           }
//           { props.post.publicVisibility &&
//             <Eye size={18} weight="bold" color={"green"} />
//           }
//           { !props.post.publicVisibility &&
//             <EyeSlash size={18} weight="bold" color={"#AAAAAA"} />
//           }
//         </Row>
//       }
//     </div>
//   )
// }
