import React, { useState } from 'react';
import { Helmet } from "react-helmet";

export default function CommonHelmet() {
  return (
    <Helmet>
      <title>Nikos: Build an authentic brand with user stories</title>
      <meta name="description" content="Create, aggregate, and showcase video testimonials, product reviews and employee stories." />
      <meta property="og:title" content="Nikos: Build an authentic brand with user stories" />
      <meta property="og:description" content="Create, aggregate, and showcase video testimonials, product reviews and employee stories." />
      <meta property="og:url" content="https://www.nikos.ai/" />
      <meta property="og:image" content="%PUBLIC_URL%/socialshare.png" />
    </Helmet>
  )
}
