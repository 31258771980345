import React from "react";
import CryptoJS from 'crypto-js';

export function bootIntercom (_uid, _name, _email, _created) {
  let hash = CryptoJS.HmacSHA256(_uid, process.env.REACT_APP_INTERCOM_SECRET);
  let hashInHex = CryptoJS.enc.Hex.stringify(hash);
  window.Intercom('boot', {
   app_id: process.env.REACT_APP_INTERCOM_APP_ID,
   name: _name,
   email: _email,
   user_id: _uid,
   created_at: parseInt(_created/1000),
   user_hash: hashInHex,
  });
};

// export function bootIntercomWithShowcaseCount (_uid, _name, _email, _created, _count) {
//   let hash = CryptoJS.HmacSHA256(_uid, process.env.REACT_APP_INTERCOM_SECRET);
//   let hashInHex = CryptoJS.enc.Hex.stringify(hash);
//   window.Intercom('boot', {
//    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
//    name: _name,
//    email: _email,
//    user_id: _uid,
//    created_at: parseInt(_created/1000),
//    number_of_showcases: _count,
//    user_hash: hashInHex,
//   });
// };

export function updateIntercom(_uid, _key, _value) {
  let hash = CryptoJS.HmacSHA256(_uid, process.env.REACT_APP_INTERCOM_SECRET);
  let hashInHex = CryptoJS.enc.Hex.stringify(hash);
  window.Intercom('update', {
    [_key]: _value,
  });
};
