import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, generateRandomString, convertToSlug } from './shared-functions.js';
import { TEMPLATES_PROMPTS } from './shared-templates.js';
import { db } from "./use-firebase.js";
import { useRequireAuth } from "./use-require-auth.js";
import { collection, addDoc } from 'firebase/firestore';
import { Row, Col, Button, Form, Card, Badge, Image, Modal } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { Confetti, Copy } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { mixpanelTrackShowcaseCreate } from './shared-mixpanel.js';
import { bootIntercom } from './shared-intercom.js';
import CommonHelmet from "./components/common/Helmet.js";

export default function ShowcasesNew() {

  const auth = useRequireAuth();
  const navigate = useNavigate();
  const props = useParams();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [validated, setValidated] = useState(false);
  const [stepNum, setStepNum] = useState(0);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [icon, setIcon] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [tips, setTips] = useState([]);
  const [emailBody, setEmailBody] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [usecases, setUsecases] = useState([]);
  const [displayType, setDisplayType] = useState('masonry');
  const [showcaseType, setShowcaseType] = useState('testimonial');
  const [templateChosen, setTemplateChosen] = useState(null);
  const [videoContent, setVideoContent] = useState(true);
  const [photoContent, setPhotoContent] = useState(true);
  const [quoteContent, setQuoteContent] = useState(true);
  const [extraName, setExtraName] = useState(true);
  const [extraJobTitle, setExtraJobTitle] = useState(true);
  const [extraLinkedin, setExtraLinkedin] = useState(true);
  const [extraTwitter, setExtraTwitter] = useState(true);
  const [extraInstagram, setExtraInstagram] = useState(true);
  const [publicVisibility, setPublicVisibility] = useState(true);
  const [usecaseFilter, setUsecaseFilter] = useState('All');
  const [industryFilter, setIndustryFilter] = useState('All');
  const [functionFilter, setFunctionFilter] = useState('All');
  const [usecaseTags, setUsecaseTags] = useState([]);
  const [newShowcaseId, setNewShowcaseId] = useState(null);
  const [newShowcaseGatherCode, setNewShowcaseGatherCode] = useState(null);
  const [showNewShowcaseModal, setShowNewShowcaseModal] = useState(false);

  useEffect(() => {
    let _usecaseTags = [];
    TEMPLATES_PROMPTS.forEach((p, i) => {
      p.usecases.forEach((t, j) => {
        if (!_usecaseTags.includes(t)) {
          _usecaseTags.push(t);
        }
      });
    });
    setUsecaseTags(_usecaseTags);
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    if (auth && auth.user && auth.workspace) {
      if (props && props.id && ['1','2','3'].includes(props.id)) {
        setTemplateChosen(props.id);
        TEMPLATES_PROMPTS.forEach((t, i) => {
          if (t.id === props.id) {
            setName(t.name);
            setShowcaseType(t.showcaseType);
            setDescription(t.showcaseDescription.replace(/__workspace__/g, auth.workspace.name));
            setPrompt(t.prompt.replace(/__workspace__/g, auth.workspace.name));
            setIcon(t.icon);
            setTips(t.tips.map(tip => { return { id: tip.id, title: tip.title.replace(/__workspace__/g, auth.workspace.name) } }));
            setEmailBody(t.emailBody.replace(/__workspace__/g, auth.workspace.name));
            setIndustries(t.industries);
            setUsecases(t.usecases);
            setFunctions(t.functions);
            setDisplayType(t.displayType);
            setStepNum(1);
          }
        });
      }
      // boot intercom
      bootIntercom(auth.user.uid, auth.user.name, auth.user.email, auth.user.createdTs);
    }
  }, [auth]);

  const handleNewValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        handleCreateNew();
      }
      setValidated(true);
    }
  };

  const handleCreateNew = () => {
    let id = generateRandomString(8);
    let gatherCode = generateRandomString(24);
    setNewShowcaseId(id);
    setNewShowcaseGatherCode(gatherCode);
    let newShowcase = {
      id: id,
      creatorId: auth.user.uid,
      workspaceId: auth.workspace.id,
      name: name,
      description: description,
      icon: icon,
      prompt: prompt,
      tips: tips,
      emailBody: emailBody,
      usecases: usecases,
      industries: industries,
      functions: functions,
      displayType: displayType,
      showcaseType: showcaseType,
      templateId: templateChosen,
      videoContent: videoContent,
      photoContent: photoContent,
      quoteContent: quoteContent,
      extraName: extraName,
      extraJobTitle: extraJobTitle,
      extraLinkedin: extraLinkedin,
      extraTwitter: extraTwitter,
      extraInstagram: extraInstagram,
      gatherCode: gatherCode,
      publicVisibility: publicVisibility,
      createdTs: Date.now(),
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "showcases"), newShowcase).then(() => {
      // doToastSuccess("New showcase created!");
      // navigate('/showcase/' + id);
      setShowNewShowcaseModal(true);
      // mixpanel track new showcase
      mixpanelTrackShowcaseCreate(auth.user.uid, auth.workspace.id, templateChosen);
    });
  };

  const editTips = (_id, _text) => {
    let _tips = []
    tips.forEach((t, i) => {
      if (t.id === _id) {
        _tips.push({ id: t.id, title: _text });
      } else {
        _tips.push(t);
      }
    });
    setTips(_tips);
  }

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <CommonHelmet />
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
        <Col xs={12} sm={11} md={11} lg={11} xl={11}>
          <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
            <h3>Create a new showcase</h3>
          </Row>
          { stepNum === 0 &&
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0, width: '100%' }}>
              <Col xs={12} sm={11} md={11} lg={11} xl={11}>
                <h4>Choose a template</h4>
                <p className="muted small" style={{ marginTop: 20 }}>Filter by usecase</p>
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 10, marginLeft: 0, marginRight: 0 }}>
                  <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: usecaseFilter === "All" ? 'lightblue' : '#eeeeee', padding: '5px 20px', cursor: 'pointer' }} onClick={() => setUsecaseFilter('All')}>All</p>
                  {
                    usecaseTags.map((t, id) => {
                      return (
                        <p className="small" style={{ marginRight: 10, marginBottom: 5, borderRadius: 20, backgroundColor: t == usecaseFilter ? 'lightblue' : '#eeeeee', padding: '5px 20px', cursor: 'pointer' }} onClick={() => setUsecaseFilter(t)}>{ t }</p>
                      )
                    })
                  }
                </Row>
                <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 20, marginLeft: 0, marginRight: 0, width: '100%' }}>
                  { TEMPLATES_PROMPTS.map((p) => {
                      if (usecaseFilter === 'All' || p.usecases.includes(usecaseFilter)) {
                        return (
                          <Col xs={12} sm={11} md={6} lg={6} xl={4} style={{ padding: 0, marginBottom: 20 }}>
                            <Card style={{ padding: 10, marginRight: 20, cursor: 'pointer', backgroundColor: templateChosen === p.id ? '#cefad0' : '#EEEEEE' }}
                              onClick={() => {
                                setTemplateChosen(p.id);
                                setIcon(p.icon);
                                setName(p.name);
                                setShowcaseType(p.showcaseType);
                                setDescription(p.showcaseDescription.replace(/__workspace__/g, auth.workspace.name));
                                setPrompt(p.prompt.replace(/__workspace__/g, auth.workspace.name));
                                setTips(p.tips.map(tip => { return { id: tip.id, title: tip.title.replace(/__workspace__/g, auth.workspace.name) } }));
                                setEmailBody(p.emailBody);
                                setDisplayType(p.displayType);
                                setUsecases(p.usecases);
                                setIndustries(p.industries);
                                setFunctions(p.functions); }}>
                              <Card.Body style={{ padding: 10 }}>
                                <Row style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, minHeight: 220 }}>
                                  <div>
                                    <Image src={p.icon} style={{ height: 30, width: 30 }} />
                                    <Card.Title style={{ marginTop: 10 }}><h5>{ p.name }</h5></Card.Title>
                                    <p className="small" style={{ marginTop: -5 }}>{ p.description }</p>
                                    { p.usecases &&
                                      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 5, marginLeft: 0, marginRight: 0 }}>
                                        <Col style={{ padding: 0 }}>
                                        {
                                          p.usecases.map((usecase) => {
                                            return (
                                              <Badge bg="light" style={{ marginRight: 10, marginBottom: 5 }}><p className="small">{ usecase }</p></Badge>
                                            )
                                          })
                                        }
                                        </Col>
                                      </Row>
                                    }
                                  </div>
                                  <Button variant="tertiary" style={{ marginTop: 20, width: '100%' }} onClick={() => {
                                    setTemplateChosen(p.id);
                                    setIcon(p.icon);
                                    setName(p.name);
                                    setShowcaseType(p.showcaseType);
                                    setDescription(p.showcaseDescription.replace(/__workspace__/g, auth.workspace.name));
                                    setPrompt(p.prompt.replace(/__workspace__/g, auth.workspace.name));
                                    setTips(p.tips.map(tip => { return { id: tip.id, title: tip.title.replace(/__workspace__/g, auth.workspace.name) } }));
                                    setEmailBody(p.emailBody);
                                    setDisplayType(p.displayType);
                                    setUsecases(p.usecases);
                                    setIndustries(p.industries);
                                    setFunctions(p.functions);
                                    setStepNum(1);
                                  }}>Choose</Button>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      }
                    })
                  }
                </Row>
                <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                  <Button variant="tertiary" onClick={() => navigate('/')}>Cancel</Button>
                  <Button variant="secondary" onClick={() => setStepNum(1)} disabled={ templateChosen ? false : true }>Next</Button>
                </Row>
              </Col>
            </Row>
          }
          { (stepNum === 1 || stepNum === 2 || stepNum === 3) &&
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 40, marginLeft: 0, marginRight: 0, width: '100%' }}>
              <Col xs={12} sm={11} md={10} lg={8} xl={8}>
                <Form noValidate validated={validated} onSubmit={handleNewValidation}>
                  { stepNum === 1 &&
                    <div>
                      <h6 className="muted">Step 1 of 3</h6>
                      <h4>Enter name & description</h4>
                      <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                        <h6 style={{ marginBottom: 0 }}>Name</h6><p className="small muted">A descriptive name for your showcase</p>
                      </Form.Label>
                      <Form.Group controlId="name">
                        <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Customer testimonials" value={name} onChange={(e) => setName(e.target.value)} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Enter a valid name 3 to 96 characters in length
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                        <h6 style={{ marginBottom: 0 }}>Description</h6><p className="small muted">Describe the purpose of this showcase to visitors. p.s. This will be displayed on the public page of the showcase.</p>
                      </Form.Label>
                      <Form.Group controlId="description">
                        <Form.Control required required as="textarea" minLength="6" maxLength="1000" rows={6} placeholder="e.g. Videos, photos and quotes share by some of our users sharing their experience using our products." value={description} onChange={(e) => setDescription(e.target.value)} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Enter a valid one liner 6 to 500 characters in length
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Row style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                        <p className="muted small">{ description.length }/1000</p>
                      </Row>
                      <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                        <Button variant="tertiary" onClick={() => setStepNum(0)}>Back</Button>
                        <Button variant="secondary" onClick={() => setStepNum(2)} disabled={ name.length < 3 || description.length < 6 ? true : false }>Next</Button>
                      </Row>
                    </div>
                  }
                  { stepNum === 2 &&
                    <div>
                      <h6 className="muted">Step 2 of 3</h6>
                      <h4>Enter prompts and tips</h4>
                      <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                        <h6 style={{ marginBottom: 0 }}>Prompt</h6><p className="small muted">What content would you like your team, customers, fans, and friends to share with you for this showcase?</p>
                      </Form.Label>
                      <Form.Group controlId="name">
                        <Form.Control required as="textarea" type="text" minLength="3" maxLength="280" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Enter a valid name 3 to 280 characters in length
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                        <h6 style={{ marginBottom: 0 }}>Tips</h6><p className="small muted">Include up to three tips that will help your team, customers, fans, and friends easily create the content you would like them to share with you for this showcase</p>
                      </Form.Label>
                      {
                        tips.map((t, id) => {
                          return (
                            <Form.Group controlId="name" style={{ marginBottom: 10 }}>
                              <Form.Control as="textarea" type="text" rows={2} minLength="3" maxLength="500" value={t.title} onChange={(e) => editTips(t.id, e.target.value)} />
                              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                Enter a valid name 3 to 280 characters in length
                              </Form.Control.Feedback>
                            </Form.Group>
                          )
                        })
                      }
                      <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                        <Button variant="tertiary" onClick={() => setStepNum(1)}>Back</Button>
                        <Button variant="secondary" onClick={() => setStepNum(3)} disabled={ prompt.length < 3 ? true : false }>Next</Button>
                      </Row>
                    </div>
                  }
                  { stepNum === 3 &&
                    <div>
                      <h6 className="muted">Step 3 of 3</h6>
                      <h4>Choose extra fields and visibility</h4>
                      <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                        <h6 style={{ marginBottom: 0 }}>Extra fields</h6><p className="small muted">Choose info you would like to collect from your responders</p>
                      </Form.Label>
                      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                        <Form.Check type={'checkbox'} id="extra-name" checked={extraName} onChange={(e) => setExtraName(e.target.checked)} label={"Name"} style={{ marginRight: 20 }} />
                        <Form.Check type={'checkbox'} id="extra-jobtitle" checked={extraJobTitle} onChange={(e) => setExtraJobTitle(e.target.checked)} label={"Job title"} style={{ marginRight: 20 }} />
                        <Form.Check type={'checkbox'} id="extra-linkedin" checked={extraLinkedin} onChange={(e) => setExtraLinkedin(e.target.checked)} label={"LinkedIn handle"} style={{ marginRight: 20 }} />
                        <Form.Check type={'checkbox'} id="extra-twitter" checked={extraTwitter} onChange={(e) => setExtraTwitter(e.target.checked)} label={"Twitter handle"} style={{ marginRight: 20 }} />
                        <Form.Check type={'checkbox'} id="extra-insta" checked={extraInstagram} onChange={(e) => setExtraInstagram(e.target.checked)} label={"Instagram handle"} />
                      </Row>
                      <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                        <h6 style={{ marginBottom: 0 }}>Public visibility</h6><p className="small muted">Choose whether you would like this showcase page to be publicly viewable. Private showcases can only be accessed by your workspace members when they are logged in. This can be changed once the showcase is created.</p>
                      </Form.Label>
                      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                        <Form.Check type={'checkbox'} id="public-visibility" checked={publicVisibility} onChange={(e) => setPublicVisibility(e.target.checked)} label={"Public"} />
                      </Row>
                      <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
                        <Button variant="tertiary" onClick={() => setStepNum(2)}>Back</Button>
                        <Button variant="secondary" type="submit" disabled={ (videoContent || photoContent || quoteContent) ? false : true }>Create</Button>
                      </Row>
                    </div>
                  }
                </Form>
              </Col>
            </Row>
          }
        </Col>
      </Row>
      <Modal
        show={showNewShowcaseModal}
        centered
        size="md"
        fullscreen='lg-down'
        style={{ backgroundColor: '#111111AA' }}
        onHide={() => setShowNewShowcaseModal(false)}>
        <Modal.Body style={{ backgroundColor: '#FFFFFF', color: '#111111', padding: 20, boxShadow: '0 5px 15px rgba(0,0,0,.5)' }}>
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
            <Col style={{ textAlign: 'center' }}>
              <Confetti size={48} color="#e2d003" weight="duotone" />
              <h4 style={{ marginTop: 20 }}>Congrats! Your new showcase is ready!</h4>
              <p className="muted" style={{ marginTop: 10 }}>Copy and send this URL below to invite submissions from your customers, users, coworkers, and friends.</p>
              <p className="small" style={{ marginTop: 40, padding: '10px 10px', backgroundColor: '#EEEEEE', cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/request/" + newShowcaseGatherCode); doToastSuccess('Copied URL to clipboard') }}><Copy size={20} /> {"https://app.nikos.ai/request/" + newShowcaseGatherCode}</p>
              <Button variant="primary" style={{ marginTop: 40, width: '100%' }} onClick={() => { setShowNewShowcaseModal(false); navigate('/showcase/' + newShowcaseId) }}>Go to showcase</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  );

}


// { false &&
//   <div>
//     <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//       <h6 style={{ marginBottom: 0 }}>Email body</h6><p className="small muted">Enter text you would like included in the request emails you send to your team, customers, fans and friends</p>
//     </Form.Label>
//     <Form.Group controlId="name">
//       <Form.Control as="textarea" type="text" maxLength="500" value={emailBody} onChange={(e) => setEmailBody(e.target.value)} />
//       <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
//       <Form.Control.Feedback type="invalid">
//         Enter a valid email up to 500 characters in length
//       </Form.Control.Feedback>
//     </Form.Group>
//   </div>
// }
// { false &&
//   <div>
//     <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//       <h6 style={{ marginBottom: 0 }}>Content types</h6><p className="small muted">Which types of content would you like to request for this showcase? Choose atleast one.</p>
//     </Form.Label>
//     <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
//       <Form.Check type={'checkbox'} id="content-video" checked={videoContent} onChange={(e) => setVideoContent(e.target.checked)} label={"Videos"} />
//       <Form.Check type={'checkbox'} id="content-photos" checked={photoContent} onChange={(e) => setPhotoContent(e.target.checked)} label={"Photos"} style={{ marginLeft: 20 }} />
//       <Form.Check type={'checkbox'} id="content-quotes" checked={quoteContent} onChange={(e) => setQuoteContent(e.target.checked)} label={"Quotes"} style={{ marginLeft: 20 }} />
//     </Row>
//   </div>
// }
