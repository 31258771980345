import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button, Image, Spinner, Card, Badge } from 'react-bootstrap';
import { } from "../../shared-functions.js";
import { db } from "../../use-firebase.js";
import { collection, getDocs, where, query, updateDoc, doc } from 'firebase/firestore';
import { Eye, Copy, PencilSimple, ArrowUpRight } from "phosphor-react";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'react-moment';

export default function Showcase(props) {

  const navigate = useNavigate();

  useEffect(() => {
    if (props && props.showcase) {
    }
  }, [props]);

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (!props || !props.showcase) {
    return (
      <Row style={{ justifyContent: 'center' }}>
        <Spinner animation="border" role="status" />
      </Row>
    )
  }

  return (
    <Card style={{ padding: 20, margin: 10, backgroundColor: '#FFFCF7', border: '1px solid #FFD99A' }}>
      <Image src={props.showcase.icon} style={{ height: 24, width: 24 }} />
      <h5 style={{ marginTop: 10, cursor: 'pointer' }} onClick={() => navigate("/showcase/" + props.showcase.id)}>{ props.showcase.name }</h5>
      <p className="">{ props.posts } posts</p>
      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 20, marginLeft: 0, marginRight: 0 }}>
        <p className="small" style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => navigate("/showcase/" + props.showcase.id)}><Eye size={18} style={{ marginRight: 5, cursor: 'pointer' }} />Open</p>
        { props.showcase.publicVisibility && <a href={"/p/" + props.showcase.id} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}><p className="small"><ArrowUpRight size={18} style={{ marginRight: 5, cursor: 'pointer' }} />View public page</p></a> }
        { props.showcase.publicVisibility && <p className="small" style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/request/" + props.showcase.gatherCode); doToastSuccess('Request link copied to clipboard') }} ><Copy size={18} style={{ marginRight: 5 }} />Copy request link</p> }
      </Row>
      <p className="tiny" style={{ marginTop: 20 }}>Created <Moment fromNow>{ props.showcase.createdTs }</Moment></p>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Card>
  )

}

// <td>
//   <Image src={props.showcase.icon} style={{ height: 18, width: 18, marginRight: 16 }} />
//   <a href={"/showcase/" + props.showcase.id}>{ props.showcase.name }</a>{ !props.showcase.publicVisibility && <span style={{ marginLeft: 10 }} className="small muted">hidden</span> }
// </td>
// <td>{ getPostCount(props.showcase.id) }</td>
// <td>
//   { props.showcase.publicVisibility && <a href={"/p/" + props.showcase.id} target="_blank" rel="noopener noreferrer"><Eye size={16} style={{ marginRight: 10, cursor: 'pointer' }} /></a> }
//   { props.showcase.publicVisibility && <Copy size={16} style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText("https://app.nikos.ai/p/" + props.showcase.id); doToastSuccess('Public page URL copied to clipboard') }} /> }
//   <PencilSimple size={16} style={{ cursor: 'pointer' }} onClick={() => { navigate('/showcases/edit/' + props.showcase.id) }} />
// </td>
// </tr>
