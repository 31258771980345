import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateRandomString, useMediaQuery } from './shared-functions.js';
import { db, storage } from "./use-firebase.js";
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useRequireAuth } from "./use-require-auth.js";
import { collection, getDocs, where, query, addDoc } from 'firebase/firestore'; // collection, addDoc, getDocs, query, where
import { Row, Col, Button, Table, Spinner, Image, Form, InputGroup, Card, Tabs, Tab } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { MonitorPlay, ImageSquare, Quotes, ArrowRight, LinkedinLogo, TwitterLogo, InstagramLogo, Checks, VideoCamera, Record, ArrowCounterClockwise, Check, NumberCircleOne, NumberCircleTwo, NumberCircleThree } from "phosphor-react";
import Logo from "./components/common/Logo.js";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import StarRatings from 'react-star-ratings';
import * as UpChunk from '@mux/upchunk';
import MuxPlayer from '@mux/mux-player-react';
import Webcam from "react-webcam";
import Resizer from "react-image-file-resizer";

export default function RequestNew() {

  // const auth = useRequireAuth();
  const navigate = useNavigate();
  const props = useParams();
  let isPageWide = useMediaQuery('(min-width: 960px)');

  const [showcase, setShowcase] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [muxId, setMuxId] = useState(null);
  const [muxPlaybackId, setMuxPlaybackId] = useState(null);
  const [showPreviewVideo, setShowPreviewVideo] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);
  const [photoUploading, setPhotoUploading] = useState(false);
  const [showWebcam, setShowWebcam] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [photoError, setPhotoError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [postType, setPostType] = useState('video');
  const [videoType, setVideoType] = useState(null);
  const [comment, setComment] = useState(null);
  const [rating, setRating] = useState(0);
  const [name, setName] = useState(null);
  const [jobTitle, setJobTitle] = useState(null);
  const [consent, setConsent] = useState(false);
  const [linkedinUsername, setLinkedinUsername] = useState(null);
  const [twitterUsername, setTwitterUsername] = useState(null);
  const [instagramUsername, setInstagramUsername] = useState(null);
  const [thanks, setThanks] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [photoProgress, setPhotoProgress] = useState(0);
  const [assetType, setAssetType] = useState(null);
  const fileInputVideo = useRef(null);
  const fileInputPhoto = useRef(null);

  useEffect(() => {
    // get collection
    if (props === undefined || props.id === undefined) {
      navigate('/notfound');
    } else {
      // getMuxLink();
      getDocs(query(collection(db, "showcases"), where("gatherCode", "==", props.id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            setShowcase(doc.data());
            // get workspace details
            getDocs(query(collection(db, "workspaces"), where("id", "==", doc.data().workspaceId), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot2) => {
              querySnapshot2.forEach((doc2) => {
                setWorkspace(doc2.data());
              });
            });
          });
        } else {
          navigate('/notfound');
        }
      });
    }
    window.scrollTo(0,0);
  }, []);

  // useEffect(() => {
  //   console.log("Subscribe", muxId);
  //   getDocs(query(collection(db, "muxvideos"), where("id", "==", muxId), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       console.log("setting id", doc.data());
  //       if (doc.data().status === 'ready' && doc.data().asset && doc.data().asset.playback_ids) {
  //         setMuxPlaybackId(doc.data().asset.playback_ids[0].id);
  //       }
  //     });
  //   });
  //   // const unsubscribe = onSnapshot(query(collection(db, "muxvideos"), where("id", "==", muxId), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE)), (querySnapshot) => {
  //   //   console.log("Accesssing doc");
  //   //   querySnapshot.forEach((doc) => {
  //   //     if (doc.data().asset && doc.data().asset.playback_ids) {
  //   //       setMuxPlaybackId(doc.data().asset.playback_ids[0].id);
  //   //     };
  //   //   });
  //   // });
  //   // unsubscribe();
  // }, [muxId]);

  const WebcamStreamCapture = () => {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [countdown, setCountdown] = useState(false);
    const [countdownCount, setCountdownCount] = useState(0);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const isInitialMount = React.useRef(true);

    const sleep = async (milliseconds) => {
      await new Promise(resolve => {
          return setTimeout(resolve, milliseconds)
      });
    };

    const doCountdown = async () => {
      setCountdown(true);
      for (let i = 0; i < 3; i++) { setCountdownCount(3-i); await sleep(1000); }
      setCountdown(false);
      handleStartCaptureClick();
    }

    const handleStartCaptureClick = React.useCallback(() => {
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm"
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = React.useCallback(
      ({ data }) => {
        if (data.size > 0) {
          setRecordedChunks((prev) => prev.concat(data));
        }
      },
      [setRecordedChunks]
    );

    // useEffect(() => {
    //   if (isInitialMount.current) {
    //     isInitialMount.current = false;
    //   } else {
    //     if (!capturing && recordedChunks.length > 0) {
    //       const blob = new Blob(recordedChunks, {
    //         type: "video/webm"
    //       });
    //       const url = URL.createObjectURL(blob);
    //       setVideoUrl(url);
    //     }
    //   }
    // }, [recordedChunks]);

    const handleStopCaptureClick = React.useCallback(() => {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const handleUpload = React.useCallback(() => {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: "video/webm;codecs=avc1"
        });
        const url = URL.createObjectURL(blob);
        setVideoUrl(url);
        // console.log("url and blob", url, blob);
        const myFile = new File([blob], 'video.webm', {
          type: blob.type,
        });
        setVideoType('recorded_video');
        // handleVideoUpload(myFile);
        handleVideoUploadToMux(myFile);
        setRecordedChunks([]);
        setShowWebcam(false);
      }
    }, [recordedChunks]);

    const handleRetake = React.useCallback(() => {
      if (recordedChunks.length) {
        setRecordedChunks([]);
      }
    }, [recordedChunks]);

    const handleCancel = React.useCallback(() => {
      setRecordedChunks([]);
      setShowWebcam(false);
    }, [recordedChunks]);

    return (
      <div>
        <Webcam
          audio={true}
          muted={true}
          ref={webcamRef}
          width="100%"
          videoConstraints={{ aspectRatio: 1.77777777778, facingMode: "user" }}
        />
        { !capturing && countdown &&
          <Row style={{ position: 'absolute', top: 20, left: 0, right: 0, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5, lineHeight: 0 }}>
            { countdownCount === 3 && <NumberCircleThree size={64} color="#fcfcfc" weight="duotone" /> }
            { countdownCount === 2 && <NumberCircleTwo size={64} color="#fcfcfc" weight="duotone" /> }
            { countdownCount === 1 && <NumberCircleOne size={64} color="#fcfcfc" weight="duotone" /> }
          </Row>
        }
        { !capturing && recordedChunks.length === 0 && !countdown &&
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5, lineHeight: 0 }}>
            <Button variant="primary" style={{ paddingTop: 5, paddingBottom: 5, borderRadius: 30 }} onClick={doCountdown}>Start recording</Button>
          </Row>
        }
        { capturing && !countdown &&
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
            <Col style={{ textAlign: 'center', padding: 0 }}>
              <p className="small text-danger">recording</p>
              <Button variant="primary" style={{ backgroundColor: 'red', color: '#EEEEEE', paddingTop: 5, paddingBottom: 5, borderRadius: 30, marginTop: 5 }} onClick={handleStopCaptureClick}>Stop recording</Button>
            </Col>
          </Row>
        }
        { !capturing && recordedChunks.length > 0 &&
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
            <Button variant="primary" style={{ backgroundColor: 'green', color: '#EEEEEE', borderRadius: 30, marginRight: 20 }} onClick={handleUpload}><Check size={18} weight="bold" color="#eeeeee" style={{ marginRight: 5, paddingBottom: 2 }} /> Save</Button>
            <Button variant="primary" style={{ marginLeft: 20, paddingTop: 5, paddingBottom: 5, borderRadius: 30 }} onClick={handleRetake}><ArrowCounterClockwise size={18} weight="bold" color="#eeeeee" style={{ marginRight: 5, paddingBottom: 2 }} /> Retake</Button>
          </Row>
        }
        { !capturing && !countdown &&
          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
            <p className="muted" style={{ cursor: 'pointer' }} onClick={handleCancel}>Cancel</p>
          </Row>
        }
      </div>
    );
  };

  const handleNewValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        if (assetType === 'photo') {
          savePhotoAsPost();
        } else {
          saveVideoAsPost();
        }
      }
      setValidated(true);
    }
  };

  const saveVideoAsPost = () => {
    let newVideoPost = {
      id: generateRandomString(16),
      showcaseId: showcase.id,
      workspaceId: showcase.workspaceId,
      name: name,
      jobTitle: jobTitle,
      linkedinUsername: linkedinUsername,
      twitterUsername: twitterUsername,
      instagramUsername: instagramUsername,
      comment: comment,
      rating: rating,
      muxId: muxId,
      muxPlaybackId: muxPlaybackId,
      type: 'video',
      videoType: videoType,
      approved: true,
      publicVisibility: true,
      createdTs: Date.now(),
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "posts"), newVideoPost).then(() => {
      doToastSuccess("Video saved!");
      setThanks(true);
    })
  };

  const getMuxPlaybackId = (_id) => {
    getDocs(query(collection(db, "muxvideos"), where("id", "==", _id), where("visibility", "==", process.env.REACT_APP_FIREBASE_DB_CODE))).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().status === 'ready' && doc.data().asset && doc.data().asset.playback_ids) {
          setMuxPlaybackId(doc.data().asset.playback_ids[0].id);
          setShowPreviewVideo(true);
        }
      });
    });
  };

  const handleVideoChange = e => {
    if (e.target.files[0]) {
      const video = e.target.files[0];
      // jpg or png images, less than 2MB
      if ((video.type === "video/mpg" || video.type === "video/mp4" || video.type === "video/mov" || video.type === "video/quicktime") && (video.size/1000000 < 200)) {
        setVideoError(false);
        setVideoType('uploaded_video');
        // handleVideoUpload(video);
        handleVideoUploadToMux(video);
      } else {
        setVideoError(true);
      }
    }
  };

  const resizeFile = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 900, 900, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64' );
  });

  const dataURIToBlob = (dataURI, filename) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    let blob = new Blob([ia], { type: mimeString });

    return new File([blob], filename, { lastModified: Date.now(), type: 'image/jpeg' });
  };

  const handlePhotoChange = async e => {
    if (e.target.files[0]) {
      const photo = e.target.files[0];
      // console.log("photo", photo);
      // jpg or png images, less than 2MB
      // && (photo.size/1000000 < 6)
      if ((photo.type === "image/png" || photo.type === "image/jpeg" || photo.type === "image/jpg")) {
        setPhotoError(false);
        const resizedPhoto = await resizeFile(photo);
        const image = dataURIToBlob(resizedPhoto, generateRandomString(16) + ".jpeg");
        // console.log("image", image);
        handlePhotoUpload(image);
      } else {
        setPhotoError(true);
      }
    }
  };

  const savePhotoAsPost = () => {
    let newPhotoPost = {
      id: generateRandomString(16),
      showcaseId: showcase.id,
      workspaceId: showcase.workspaceId,
      name: name,
      jobTitle: jobTitle,
      linkedinUsername: linkedinUsername,
      twitterUsername: twitterUsername,
      instagramUsername: instagramUsername,
      comment: comment,
      photoUrl: photoUrl,
      type: 'photo',
      approved: true,
      publicVisibility: true,
      createdTs: Date.now(),
      visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
    };
    addDoc(collection(db, "posts"), newPhotoPost).then(() => {
      doToastSuccess("Photo saved!");
      setPhotoUrl(null);
      setThanks(true);
    })
  };

  const handlePhotoUpload = (cover) => {
    setPhotoUploading(true);
    let id = generateRandomString(24);
    const metadata = { contentType: cover.type, customMetadata: { ruleCode: process.env.REACT_APP_FIREBASE_STORAGE_CODE }};
    const storageRef = ref(storage, 'photos/' + id);
    const uploadTask = uploadBytesResumable(storageRef, cover, metadata);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        setPhotoProgress(progress);
        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
        }
      },
      (error) => {
        setPhotoUploading(false);
        console.log("photo upload error");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          setAssetType('photo');
          setPhotoUploading(false);
          setPhotoUrl(downloadUrl);
        });
      }
    );
  };

  const handleVideoUpload = (video) => {
    let id = generateRandomString(24);
    setVideoUploading(true);
    const metadata = { contentType: video.type, customMetadata: { ruleCode: process.env.REACT_APP_FIREBASE_STORAGE_CODE }};
    const storageRef = ref(storage, 'videos/' + id);
    const uploadTask = uploadBytesResumable(storageRef, video, metadata);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        // setVideoProgress(progress);
        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            break;
        }
      },
      (error) => {
        setVideoUploading(false);
        console.log("video upload error");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          setVideoUrl(downloadUrl);
          setAssetType('video');
          setVideoUploading(false);
        });
      }
    );
  };

  const handleVideoUploadToMux = (video) => {
    setShowPreviewVideo(false);
    setVideoUploading(true);
    fetch('https://us-central1-nikos-app.cloudfunctions.net/getMuxLink', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        key: 'value',
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("response", responseJson);
      let uploadUrl = responseJson.url;
      setMuxId(responseJson.id)
      // upload
      const upload = UpChunk.createUpload({
        endpoint: uploadUrl, // Authenticated url
        file: video, // File object with your video file’s properties
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });
      // Subscribe to events
      upload.on('error', error => {
        // console.log("error", error.detail);
        doToastError("Something went wrong. Try again.");
        setVideoUploading(false);
      });
      upload.on('progress', progress => {
        setVideoProgress(progress.detail);
        // console.log("progress", progress.detail);
      });
      upload.on('success', () => {
        setShowPreviewVideo(true);
        setAssetType('video');
        setVideoUploading(false);
        const timer = setTimeout(() => {
          getMuxPlaybackId(responseJson.id);
        }, 3000);
        return () => clearTimeout(timer);
      });
    })
  };

  const doToastSuccess = (text) => {
    toast.success(text, {
      position: "top-center",
      theme: "light",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  const doToastError = (text) => {
    toast.error(text, {
      position: "top-center",
      theme: "light",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    })
  };

  if (showcase === null || workspace === null) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <Spinner animation="border" role="status" />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ justifyContent: 'center', marginBottom: 40 }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
        <Col xs={12} sm={12} md={12} lg={11} xl={11}>
          { workspace && workspace.logoUrl &&
            <Image src={ workspace.logoUrl } style={{ maxHeight: 40, objectFit: 'cover' }} />
          }
          { workspace && !workspace.logoUrl &&
            <h2>{ workspace.name }</h2>
          }
          { !thanks &&
            <div>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                <Col xs={12} sm={12} md={10} lg={7} xl={6}>
                  <h3 className="muted" style={{ marginTop: 20 }}>Share a { showcase.showcaseType }</h3>
                  <h4 style={{ marginTop: 20 }}>{ showcase.prompt }</h4>
                  { showcase.tips && showcase.tips.length > 0 &&
                    <div style={{ marginTop: 20 }}>
                      <p className="muted">Consider these prompts ...</p>
                      {
                        showcase.tips.map((t) => {
                          if (t.title && t.title.length > 0) {
                            return (
                              <p className="muted">{ t.title }</p>
                            )
                          }
                        })
                      }
                    </div>
                  }
                </Col>
              </Row>
              <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                <Col xs={12} sm={10} md={6} lg={7} xl={7} style={{ marginBottom: 20, maxWidth: 600 }}>
                  <Form noValidate validated={validated} onSubmit={handleNewValidation}>
                    <InputGroup className="mb-3">
                      <Form.Control type="file" onChange={handleVideoChange} ref={fileInputVideo} style={{ display: 'none' }}/>
                    </InputGroup>
                    { videoUploading &&
                      <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, minHeight: 110 }}>
                        <Spinner />
                        <p className="small muted" style={{ marginTop: 10 }}>Uploading ... { parseInt(videoProgress) }%</p>
                      </Row>
                    }
                    <InputGroup className="mb-3">
                      <Form.Control type="file" onChange={handlePhotoChange} ref={fileInputPhoto} style={{ display: 'none' }}/>
                    </InputGroup>
                    { photoUploading &&
                      <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, minHeight: 110 }}>
                        <Spinner />
                        <p className="small muted" style={{ marginTop: 10 }}>Uploading ... { parseInt(photoProgress) }%</p>
                      </Row>
                    }
                    { !showWebcam && !videoUploading &&
                      <div>
                        <Card style={{ backgroundColor: '#eeeeee', height: 80, cursor: 'pointer' }} onClick={() => setShowWebcam(true)}>
                          <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0 }}>
                            <h5 className="muted">Record a video</h5>
                          </Row>
                        </Card>
                        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                          <p className="small" style={{ cursor: 'pointer', marginRight: 10 }} onClick={() => fileInputVideo.current.click()}>upload a video</p>
                          <p className="small" style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => fileInputPhoto.current.click()}>upload a photo</p>
                        </Row>
                      </div>
                    }
                    { showWebcam &&
                      <div style={{ width: '100%' }}>
                        <WebcamStreamCapture />
                      </div>
                    }
                    { videoError &&
                      <p className="small text-danger" style={{ marginTop: 5 }}>Upload MP4/MPG/MOV files less than 200MB in size</p>
                    }
                    { photoError &&
                      <p className="small text-danger" style={{ marginTop: 5 }}>Upload PNG/JPG images less than 6MB in size</p>
                    }
                    { assetType === 'photo' && photoUrl &&
                      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 10, width: '100%' }}>
                        <Image src={photoUrl} style={{ height: 60, objectFit: 'cover' }} />
                      </Row>
                    }
                    <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                      <h6>Add a Comment</h6>
                    </Form.Label>
                    <Form.Group controlId="comment">
                      <Form.Control as="textarea" type="text" rows={2} maxLength="500" value={comment} onChange={(e) => setComment(e.target.value)} />
                    </Form.Group>
                    <div style={{ marginTop: 10 }} />
                    { showcase.extraName &&
                      <div>
                        <Form.Label style={{ marginTop: 10, marginBottom: 5 }}>
                          <h6>Your name</h6>
                        </Form.Label>
                        <Form.Group controlId="name">
                          <Form.Control required as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Ava Ko" value={name} onChange={(e) => setName(e.target.value)} />
                          <Form.Control.Feedback type="invalid">
                            Enter a valid name 3 to 96 characters in length
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    }
                    { showcase.extraJobTitle &&
                      <div>
                        <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                          <h6>Your job title</h6>
                        </Form.Label>
                        <Form.Group controlId="jobtitle">
                          <Form.Control as="input" type="text" minLength="3" maxLength="96" placeholder="e.g. Director of Marketing" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                          <Form.Control.Feedback type="invalid">
                            Enter a valid job title 3 to 96 characters in length
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    }
                    { showcase.extraLinkedin &&
                      <div>
                        <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                          <h6>Your LinkedIn handle</h6>
                        </Form.Label>
                        <Form.Group controlId="linkedin">
                          <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={linkedinUsername} onChange={(e) => setLinkedinUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                          <Form.Control.Feedback type="invalid">
                            Enter a valid LinkedIn handle up to 96 characters long
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    }
                    { showcase.extraTwitter &&
                      <div>
                        <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                          <h6>Your Twitter handle</h6>
                        </Form.Label>
                        <Form.Group controlId="twitter">
                          <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={twitterUsername} onChange={(e) => setTwitterUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                          <Form.Control.Feedback type="invalid">
                            Enter a valid Twitter handle up to 96 characters long
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    }
                    { showcase.extraInstagram &&
                      <div>
                        <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
                          <h6>Your Instagram handle</h6>
                        </Form.Label>
                        <Form.Group controlId="instagram">
                          <Form.Control as="input" type="text" maxLength="96" placeholder="e.g. avako" value={instagramUsername} onChange={(e) => setInstagramUsername(e.target.value.toLowerCase().replace(/\s/g, ''))} />
                          <Form.Control.Feedback type="invalid">
                            Enter a valid Instagram handle up to 96 characters long
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    }
                    <Form.Label style={{ marginTop: 30, marginBottom: 5 }}>
                      <h6>Consent</h6>
                    </Form.Label>
                    <Form.Check type={'checkbox'} id="consent" onChange={(e) => setConsent(e.target.checked)}
                      label={"I provide my consent to " + workspace.name + " to use this content and the additional details for internal as well as publicly visible pages on this site."} />
                    <Button variant="primary" type="submit" style={{ marginTop: 20 }} disabled={(!showPreviewVideo && !comment) || !consent ? true : false }>Submit</Button>
                  </Form>
                </Col>
                <Col xs={12} sm={10} md={6} lg={5} xl={5}>
                  <h6 style={{ marginTop: 15 }}>Preview</h6>
                  <Card style={{ border: '1px solid #EEEEEE', padding: 20, marginTop: 20, minHeight: 300, maxWidth: 400 }}>
                    { !showPreviewVideo && !muxPlaybackId && !videoUrl && !photoUrl &&
                      <Card style={{ height: 220, backgroundColor: '#EEEEEE' }}>
                      </Card>
                    }
                    { assetType === 'video' && !showPreviewVideo && !muxPlaybackId && videoUrl &&
                      <video width="100%" controls>
                        <source src={videoUrl} type="video/webm" />
                      </video>
                    }
                    { assetType === 'video' && showPreviewVideo && !muxPlaybackId && videoUrl &&
                      <video width="100%" controls>
                        <source src={videoUrl} type="video/webm" />
                      </video>
                    }
                    { assetType === 'video' && showPreviewVideo && !muxPlaybackId && !videoUrl &&
                      <Card style={{ height: 200, backgroundColor: '#EEEEEE' }}>
                        <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
                          <Checks size={22} color="#02ca4f" weight="bold" />
                          <p className="bold small" style={{ color: '#02ca4f', marginTop: 5 }}>Uploaded</p>
                        </Row>
                      </Card>
                    }
                    { assetType === 'video' && showPreviewVideo && muxPlaybackId &&
                      <MuxPlayer
                        streamType="on-demand"
                        playbackId={muxPlaybackId}
                        primaryColor="#CCCCCC"
                        metadata={{
                          video_id: muxId,
                          video_title: name
                        }}
                      />
                    }
                    { assetType === 'photo' && photoUrl &&
                      <Image src={photoUrl} style={{ height: 220, objectFit: 'cover' }} />
                    }
                    { comment &&
                      <p style={{ marginTop: 5 }}>"{ comment }"</p>
                    }
                    { name &&
                      <h6 style={{ marginTop: 10 }}>{ name }</h6>
                    }
                    { jobTitle &&
                      <p className="small muted" style={{ marginTop: 0 }}>{ jobTitle }</p>
                    }
                    { (linkedinUsername || twitterUsername) &&
                      <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
                        { linkedinUsername &&
                          <LinkedinLogo size={20} style={{ marginRight: 10 }}/>
                        }
                        { twitterUsername &&
                          <TwitterLogo size={20} style={{ marginRight: 10 }}/>
                        }
                        { instagramUsername &&
                          <InstagramLogo size={20} />
                        }
                      </Row>
                    }
                  </Card>
                </Col>
              </Row>
            </div>
          }
          { thanks &&
            <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 40 }}>
              <Col xs={12} sm={10} md={6} lg={5} xl={5}>
                <h3>Thank you!</h3>
                <p style={{ marginTop: 10 }}>Your { showcase.showcaseType } will be made public once the team has reviewed it. To upload more, click the button below.</p>
                <Button variant="primary" style={{ marginTop: 20 }}><a href={"/request/" + props.id } style={{ color: '#EEEEEE' }}>Submit another post</a></Button>
                <p className="small" style={{ marginTop: 20 }}><a href={"/"}>or build your own showcase</a></p>
              </Col>
              <Footer />
            </Row>
          }
        </Col>
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Row>
  )

}


// <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//   <h6>Add a Rating</h6>
// </Form.Label>
// <div>
//   <StarRatings
//     rating={rating}
//     starRatedColor="orange"
//     changeRating={(val) => setRating(val)}
//     numberOfStars={5}
//     name='rating'
//     starDimension={30}
//     starSpacing={1}
//     style={{ width: 100 }}
//   />
// </div>

// const handleVideoUpload = (video) => {
//   let id = generateRandomString(24);
//   const metadata = { contentType: video.type, customMetadata: { ruleCode: process.env.REACT_APP_FIREBASE_STORAGE_CODE }};
//   const storageRef = ref(storage, 'videos/' + id);
//   const uploadTask = uploadBytesResumable(storageRef, video, metadata);
//   uploadTask.on('state_changed',
//     (snapshot) => {
//       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//       // console.log('Upload is ' + progress + '% done');
//       setVideoProgress(progress);
//       switch (snapshot.state) {
//         case 'paused':
//           break;
//         case 'running':
//           break;
//       }
//     },
//     (error) => {
//       console.log("video upload error");
//     },
//     () => {
//       getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
//         setVideoUrl(downloadUrl);
//       });
//     }
//   );
// };

// { false &&
//   <Tabs id="content-selector" activeKey={key} onSelect={(k) => { setKey(k); setPostType(k); }}>
//     { showcase.videoContent &&
//       <Tab eventKey="video" title="Upload video">
//         <Card style={{ padding: 0, marginTop: 20 }}>
//           <InputGroup className="mb-3">
//             <Form.Control type="file" onChange={handleVideoChange} style={{ border: 0, padding: 0, backgroundColor: '#FFFFFF' }}/>
//           </InputGroup>
//           { videoError &&
//             <p className="small text-danger">Upload MP4/MPG/MOV files less than 50MB in size</p>
//           }
//           { videoProgress > 0 && videoProgress < 100 &&
//             <p className="small muted" style={{ marginBottom: 10 }}>Uploading ... { parseInt(videoProgress) }%</p>
//           }
//           <Form.Label style={{ marginTop: 0, marginBottom: 5 }}>
//             <h6>Comment</h6><p className="small muted">Optional</p>
//           </Form.Label>
//           <Form.Group controlId="name">
//             <Form.Control as="input" type="text" maxLength="96" value={comment} onChange={(e) => setComment(e.target.value)} />
//           </Form.Group>
//         </Card>
//       </Tab>
//     }
//     { showcase.photoContent &&
//       <Tab eventKey="photo" title="Upload photo">
//         <Card style={{ padding: 0, marginTop: 20 }}>
//           <InputGroup className="mb-3">
//             <Form.Control type="file" onChange={handlePhotoChange} style={{ border: 0, padding: 0, backgroundColor: '#FFFFFF' }}/>
//           </InputGroup>
//           { photoError &&
//             <p className="small text-danger">Upload PNGs/JPGs less than 2MB in size</p>
//           }
//           { photoProgress > 0 && photoProgress < 100 &&
//             <p className="small muted" style={{ marginBottom: 10 }}>Uploading ... { parseInt(photoProgress) }%</p>
//           }
//           <Form.Label style={{ marginTop: 0, marginBottom: 5 }}>
//             <h6>Comment</h6><p className="small muted">Optional</p>
//           </Form.Label>
//           <Form.Group controlId="name">
//             <Form.Control as="input" type="text" maxLength="96" value={comment} onChange={(e) => setComment(e.target.value)} />
//           </Form.Group>
//         </Card>
//       </Tab>
//     }
//     { showcase.quoteContent &&
//       <Tab eventKey="quote" title="Type quote">
//         <Card style={{ padding: 0, marginTop: 20 }}>
//           <Form.Label style={{ marginBottom: 5 }}>
//             <h6>Rating</h6>
//           </Form.Label>
//           <div>
//             <StarRatings
//               rating={rating}
//               starRatedColor="orange"
//               changeRating={(val) => setRating(val)}
//               numberOfStars={5}
//               name='rating'
//               starDimension={30}
//               starSpacing={1}
//               style={{ width: 100 }}
//             />
//           </div>
//           <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
//             <h6>Quote</h6>
//           </Form.Label>
//           <Form.Group controlId="quote">
//             <Form.Control as="textarea" type="text" minLength="3" maxLength="500" value={quote} onChange={(e) => setQuote(e.target.value)} />
//             <Form.Control.Feedback type="invalid">
//               Enter a valid name 3 to 96 characters in length
//             </Form.Control.Feedback>
//           </Form.Group>
//         </Card>
//       </Tab>
//     }
//   </Tabs>
// }

// const saveQuoteAsPost = () => {
//   let newPhotoPost = {
//     id: generateRandomString(16),
//     showcaseId: showcase.id,
//     workspaceId: showcase.workspaceId,
//     name: name,
//     jobTitle: jobTitle,
//     linkedinUsername: linkedinUsername,
//     twitterUsername: twitterUsername,
//     quote: quote,
//     rating: rating,
//     type: 'quote',
//     approved: true,
//     publicVisibility: true,
//     createdTs: Date.now(),
//     visibility: process.env.REACT_APP_FIREBASE_DB_CODE,
//   };
//   addDoc(collection(db, "posts"), newPhotoPost).then(() => {
//     doToastSuccess("Quote saved!");
//     setQuote(null);
//     setThanks(true);
//   })
// };
//
// { false &&
//   <Card style={{ border: '1px solid #EEEEEE', padding: 20, marginTop: 20, minHeight: 300 }}>
//     { photoUrl && postType === 'photo' &&
//       <Image src={ photoUrl } style={{ width: '100%', objectFit: 'cover' }} />
//     }
//     { videoUrl && postType === 'video' &&
//       <ReactPlayer
//           id="1"
//           url={videoUrl}
//           height="100%"
//           width="100%"
//           controls={true}
//           volume={1}
//           muted={false}
//       />
//     }
//     { !videoUrl && postType === 'video' &&
//       <Card style={{ height: 200, backgroundColor: '#EEEEEE' }}>
//       </Card>
//     }
//     <div style={{ marginTop: 5 }}>
//       <StarRatings
//         rating={rating}
//         starRatedColor="orange"
//         changeRating={(val) => setRating(val)}
//         numberOfStars={5}
//         name='rating'
//         starDimension={20}
//         starSpacing={1}
//         style={{ width: 100 }}
//       />
//     </div>
//     { comment &&
//       <p style={{ marginTop: 10 }}>"{ comment }"</p>
//     }
//     { name &&
//       <h5 style={{ marginTop: 10 }}>{ name }</h5>
//     }
//     { jobTitle &&
//       <p className="small muted" style={{ marginTop: 0 }}>{ jobTitle }</p>
//     }
//     { (linkedinUsername || twitterUsername) &&
//       <Row style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 5 }}>
//         { linkedinUsername &&
//           <LinkedinLogo size={20} style={{ marginRight: 10 }}/>
//         }
//         { twitterUsername &&
//           <TwitterLogo size={20} style={{ marginRight: 10 }}/>
//         }
//       </Row>
//     }
//   </Card>
// }
