import React, { useState, useEffect } from 'react';
import { useAuth } from "./use-firebase.js";
import { useNavigate } from 'react-router-dom';
import Logo from "./components/common/Logo.js"
import { Row, Col, Card, Image, Button, Form, Spinner } from "react-bootstrap";
import CommonHelmet from "./components/common/Helmet.js";

export default function Login(props) {

  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }
    window.scrollTo(0,0);
  }, [auth]);

  const handleValidation = (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        // saveProfile();
      }
      setValidated(true);
    }
  };

  if (auth && auth.authenticating) {
    return (
      <Row style={{ justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
        <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, height: '80vh' }}>
          <p className="small muted">Authenticating .. hold on a sec</p>
          <Spinner animation="border" role="status" style={{ marginTop: 20 }} />
        </Row>
      </Row>
    )
  }

  return (
    <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 60, marginBottom: 40, height: '80vh' }}>
      <CommonHelmet />
      <Col xs={12} sm={12} md={8} lg={6} xl={5}>
        <Card style={{ border: '1px solid #eeeeee', padding: 40 }}>
          <h3>Login</h3>
          <Image src={"/assets/buttons/signinwithgoogle.png"} style={{ width: 200, marginTop: 20, cursor: 'pointer', textAlign: 'center' }} onClick={() => auth.googleAuthenticate()} />
          <h5 style={{ marginTop: 20 }}>or sign in with email</h5>
          <Form noValidate validated={validated} onSubmit={handleValidation} style={{ marginTop: 10 }}>
            <Form.Label style={{ marginTop: 10, marginBottom: 5 }}>
              <h6>Email</h6>
            </Form.Label>
            <Form.Group controlId="email">
              <Form.Control required as="input" type="email" minLength="3" maxLength="96" value={email} onChange={(e) => setEmail(e.target.value)} />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Enter a valid email
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Label style={{ marginTop: 20, marginBottom: 5 }}>
              <h6>Password</h6>
            </Form.Label>
            <Form.Group controlId="password">
              <Form.Control required as="input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>
            { error &&
              <p className="text-danger small" style={{ marginTop: 20 }}>Invalid credentials. Try again</p>
            }
            <Row style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Button variant="primary" onClick={() => { auth.signin(email, password).then(response => { if (response.message != null) { setError(response); setEmail(""); setPassword(""); } }) }}>Login</Button>
              <p className="small"><a href="/forgotpassword">Forgot password?</a></p>
            </Row>
          </Form>
          <p className="small" style={{ marginTop: 10 }}>No account yet?&nbsp;&nbsp;<a href="/signup">Create account</a></p>
        </Card>
      </Col>
    </Row>
  );
}
